@import "../../App.scss";

.skillmap-holder {
    width: 92%;
    margin-left: 5px;
    margin-top: 10px;
    h3 {
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #333269;
    }
    .has-search {
        position: relative;
        .form-control-feedback {
            position: absolute;
            z-index: 0;
            display: block;
            height: 2.375rem;
            line-height: 2.375rem;
            text-align: center;
            pointer-events: none;
            color: #aaa;
            margin-top: 4px;
            width: 24px;
            margin-left: 9px;
            img {
                width: 15px;
            }
        }

        .form-control {
            padding-left: 2.3rem;
            border-radius: 50px;
        }
        input {
            &::placeholder {
                font-family: Roboto;
                font-size: 12px;
                font-weight: 300;
                line-height: 18px;
                text-align: justified;
            }
        }
    }
    .skill-tabs {
        margin-top: 25px;
        display: flex;
        list-style: none;
        li {
            margin-right: 32px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #333269;
            padding-bottom: 8px;
            cursor: pointer;
            &.active {
                border-bottom: 3px solid #5855d6;
                font-weight: 800;
            }
        }
    }

    h4 {
        //styleName: Section heading;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #333269;
    }

    .explore-skills {
        position: relative;
        margin-top: 20px;
        ul {
            list-style: none;
            width: 88%;
            display: flex;
            align-self: stretch;
            flex-wrap: wrap;
            display: -webkit-flex;
            align-items: inherit;
            flex-wrap: wrap;
            li {
                // padding: 10px 15px;
                margin-right: 12px;
                background-color: #fff;
                box-shadow: 0px 0px 2px rgba(61, 60, 60, 0.25);
                border-radius: 4px;
                width: 21.2%;
                cursor: pointer;
            }
        }
        .card {
            border: none;
            border-radius: 8px;
            border: 1px solid #0000001a;
            padding: 0px;
            .card-body {
                min-height: 75px;
                // padding-top: 5px !important;
                h4 {
                    padding: 5px 0px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #3f51b5;
                }
            }
            .card-img-top {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                height: 118px;
            }
            h5 {
                font-family: Roboto;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #101828;
                height: 40px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            p {
                display: flex;
                font-family: Roboto;
                font-size: 10px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #667085;
            }
        }
        .slick-track {
            position: relative;
            display: flex !important;
            align-items: stretch;
            top: 0;
            left: 0;
            margin-left: 0px !important;
        }
        .slick-list {
            overflow: hidden;
            position: relative;
            display: block;
            margin: 0;
            padding: 0;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        .slick-slider {
            box-sizing: border-box;
            position: relative;
            display: block;
        }
        .slick-disabled {
            opacity: 0 !important;
        }

        .slick-slide {
            height: auto !important;
            margin-right: 12px;
            .card {
                min-height: 145px;
                cursor: pointer;
            }
        }

        .slick-disabled {
            opacity: 0% !important;
        }
        .slick-next {
            right: 25px;
            top: 45%;
            width: 0px;
            height: 0px;
            z-index: 1;
            position: absolute;
            cursor: pointer;
            &:before {
                font-size: 0px;
                opacity: 0;
                display: none;
            }
        }
        .slick-prev {
            left: -25px;
            top: 45%;
            width: 0px;
            height: 0px;
            z-index: 1;
            position: absolute;
            cursor: pointer;
            &:before {
                opacity: 0;
                display: none;
                font-size: 0px;
            }
        }
    }
    .empty-list {
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 150px;
        margin-top: 8px;
        img {
            width: 58px;
            height: 71px;
        }
        p {
            //styleName: Body text;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            color: #272727;
        }
    }
}

//Explore skills

.subject-holder {
    h1 {
        //styleName: Sub headings;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $heading;
    }
    .has-search {
        position: relative;
        margin-right: 60px;
        .form-control-feedback {
            position: absolute;
            z-index: 0;
            display: block;
            height: 2.375rem;
            line-height: 2.375rem;
            text-align: center;
            pointer-events: none;
            color: #aaa;
            margin-top: 0px;
            width: 24px;
            margin-left: 9px;
            img {
                width: 15px;
            }
        }

        .form-control {
            padding-left: 2.3rem;
            border-radius: 50px;
        }
        input {
            &::placeholder {
                font-family: Roboto;
                font-size: 12px;
                font-weight: 300;
                line-height: 18px;
                text-align: justified;
            }
        }
    }
    h2 {
        //styleName: Section heading;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $heading;
    }
    .card {
        margin-top: 10px;
        padding: 0px;
        border-radius: 8px;
        .card-body {
            min-height: 75px;
            // padding-top: 5px !important;
            h4 {
                padding: 5px 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #3f51b5;
            }
            h5 {
                font-family: Roboto;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #101828;
                height: 40px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            p {
                display: flex;
                font-family: Roboto;
                font-size: 10px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #667085;
            }
        }
        .card-img-top {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            height: 118px;
        }
    }
}

.skill-path {
    width: 88%;
    margin-left: 20px;
    .skill-path-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bookmark {
            color: $button-color;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            img {
                vertical-align: middle;
                width: 18px;
                height: 24px;
            }
        }
        h3 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #484750;
            p {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #9b9aa3;
            }
        }
    }
    h4 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: $dark-background;
    }
    .bubble-details {
        width: 100%;
        margin-top: 3px;
        ul {
            list-style-type: none;
            li {
                padding: 10px 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #fff;
                border-bottom: 5px solid #f6f6f6;
                cursor: pointer;
                font-style: normal;
                font-family: Roboto;
                .bubble-title {
                    width: 50%;
                    p {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #222222;
                    }
                }
                h2 {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #555555;
                    width: 25%;
                }
                .bubble-time {
                    width: 15%;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #555555;
                    text-align: center;
                }
                .bubble-progress {
                    width: 15%;
                }
                .due-date {
                    p {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 10px;
                        color: #555555;
                    }
                    span {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 10px;
                        color: #9b9aa3;
                    }
                }
            }
        }
    }
    .test-card {
        --gap: 16px;
        --gap-sm: 0.5rem;
        --radius: 12px;
        border-radius: var(--radius);
        width: 250px !important;
        min-width: none !important;
        max-width: 100%;
        padding: var(--gap);
        background: #ffffff;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        padding-bottom: 0px;
        margin-right: 15px;
        .card__img {
            position: relative;
            aspect-ratio: 10 / 9;
            // width: 168px;
            height: 112px;
            img {
                border-radius: var(--radius);
                object-fit: cover;
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
        }
        .card__body {
            padding: var(--gap) 0;
            h1 {
                // margin-bottom: var(--gap-sm);
                font-family: Roboto;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
            }
            p {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
            }
            .elipsis {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            .test-start-btn {
                margin-top: 10px;
                display: flex;
                align-items: center;
                img {
                    cursor: pointer;
                }
                button {
                    background-color: $button-color;
                    border: none;
                    color: #fff;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    width: 100%;
                    height: 40px;
                    border-radius: 4px;
                }
                img {
                    vertical-align: middle;
                    width: 28px;
                    height: 28px;
                }
                /* Tooltip container */
                .tooltip1 {
                    position: relative;
                    display: inline-block;

                    .tooltiptext {
                        visibility: hidden;
                        width: 100px;
                        background-color: grey;
                        color: #fff;
                        text-align: center;
                        padding: 3px 0;
                        border-radius: 4px;
                        font-size: 10px;
                        /* Position the tooltip text - see examples below! */
                        position: absolute;
                        z-index: 1;
                    }
                    &:hover .tooltiptext {
                        visibility: visible;
                    }
                }
            }
        }
    }
    .content-1 {
        background-color: #fff;
        width: 180px;
        height: 80px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 16px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 7px;
        img {
            width: 56px;
            height: 56px;
        }
        h2 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #484750;
            text-align: center;
            p {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 14px;
                color: #696873;
            }
        }
    }
}
//test details
.test-details1 {
    width: 472px;
    .sticky-close {
        position: absolute;
        right: 13px;
        top: 13px;
        cursor: pointer;
    }
    h1 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        // color: $heading-color;
        margin-top: 10px;
    }
    p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #222222;
    }
    .version-details {
        margin-bottom: 15px;
        margin-top: 20px;
        font-size: 14px;
        ol {
            list-style-type: decimal;
            color: #222222;
            font-weight: 500;
        }
    }
}

@media (max-width: 767px) {
    .skillmap-holder {
        margin-top: 0px;
        margin-bottom: 55px;
        margin-left: 15px;
        margin-right: 15px;
        width: 95%;
        .empty-list {
            padding: 75px;
        }
        h3 {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
        }
        .skill-tabs {
            width: 100%;
            li {
                width: 50%;
                text-align: center;
            }
        }
        .has-search {
            display: none;
        }
        .explore-skills {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .subject-holder {
        padding: 20px;
    }
    .test-details1 {
        .MuiDrawer-paperAnchorBottom {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            height: 100vh;
        }
        .modal-body {
            padding: 22px 32px !important;
        }
    }
}
