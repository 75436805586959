@import "../../App.scss";

.practise-test-holder {
    width: 94%;
    margin-left: 5px;
    .pt-desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
    }
    h3 {
        //styleName: Sub headings;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $heading;
    }

    .test-dashboard {
        background-color: #fff;
        padding: 12px 24px;
        margin-top: 18px;
        width: 100%;
        border-radius: 4px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        min-height: 300px;
        .select-test {
            width: 100%;
            label {
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left;
                color: #484750;
                margin-top: 15px;
            }

            .dropdown-container {
                width: 145px;
                position: relative;
                .dropdown-header {
                    margin-bottom: 3px;
                    padding: 0.5rem 0.5rem;
                    color: $heading;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 800;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;

                    border: none;
                    background-image: url("../../assets/arrow-down.svg");
                    background-position: center right;
                    background-repeat: no-repeat;
                    background-size: 15px;
                    cursor: pointer;
                    white-space: pre-wrap;
                }
            }
            p {
                color: #9b9aa3;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0px;
            }
        }
        .progres-test {
            margin-top: 15px;
            .card {
                border: 1px solid #0000001a;
                border-radius: 8px;
                height: 160px;
                padding: 0px 10px;
                cursor: pointer;
                // border: none;
                // margin-right: 10px;
                &:hover {
                    background: #f7f7fa;
                }
                h1 {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    text-align: left;
                    color: #272727;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    height: 48px;
                }
                h3 {
                    margin: 10px 0px;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.4000000059604645px;
                    text-align: left;
                    color: #525252;
                }
                p {
                    font-family: Lato;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: -0.4000000059604645px;
                    text-align: left;
                    color: #262626;
                }
            }
            .slick-track {
                display: flex !important;
                align-items: stretch;
            }

            .slick-slide {
                height: 210px !important;
            }
            .slick-slide {
                // width: 175px!important;
                margin-right: 10px;
                &:last-child {
                    margin-right: 40px;
                }
            }
            .slick-disabled {
                opacity: 0% !important;
            }
            .slick-next {
                right: 10px;
                top: 25%;
                width: 0px;
                height: 0px;
                position: absolute;
                cursor: pointer;
                &:before {
                    font-size: 0px;
                    opacity: 0;
                    display: none;
                }
            }
            .slick-prev {
                position: absolute;
                left: -25px;
                top: 25%;
                width: 0px;
                height: 0px;
                z-index: 1;
                cursor: pointer;
                &:before {
                    opacity: 0;
                    display: none;
                    font-size: 0px;
                }
            }

            .active-subject {
                background-color: #f2f2ff !important;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    bottom: -25px;
                    left: 45%;
                    border-width: 9px;
                    border-style: solid;
                    border-color: #f2f2ff transparent transparent transparent;
                }
                h1 {
                    color: #413c69;
                }
                // span {
                //     color: white !important;
                // }
            }
        }
    }
    .empty-test {
        background-color: #fff;
        width: 100%;
        padding: 52px;
        right: 0px;
        left: 1px;
        margin-top: 10px;
        .no-test {
            h4 {
                font-weight: 600;
            }
            p {
                padding: 10px;
                text-align: center;
                width: 350px;
            }
        }
    }
    .test-details {
        margin-top: 12px;
        h3 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: $heading;
        }
        .toggle {
            display: flex;
            align-items: center;
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
            }
            .react-switch-handle {
                width: 15px !important;
                height: 15px !important;
                top: 4.5px !important;
                left: 4px;
            }
        }

        .test-wise-details {
            .table-admin {
                // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                background: #fff;
                margin-top: 10px;
                .table thead tr#picksHeading th {
                    &:first-child {
                        box-shadow: none;
                        // border-radius: 4px 0px 0px 8px;
                        // -moz-border-radius: 4px 0px 0px 8px;
                        // -webkit-border-radius: 4px 0px 0px 8px;
                        // -khtml-border-radius: 4px 0px 0px 8px;
                    }
                    &:last-child {
                        // border-radius: 0px 4px 8px 0px;
                        // -webkit-border-radius: 0px 4px 8px 0px;
                        box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
                    }
                }
                .table {
                    margin-bottom: 0px;

                    thead {
                        tr {
                            box-shadow: none;
                            border-bottom: 3px solid #f6f6f6 !important;
                            th {
                                background: #eeedff;
                                border-top: none;
                                color: $heading;
                                //styleName: Section heading;
                                font-family: Roboto;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 32px;
                                letter-spacing: 0em;
                                // text-align: center;
                                vertical-align: middle;
                                border-bottom: 0px;
                            }
                        }
                    }

                    tbody {
                        tr {
                            .empty-list {
                                background: #fff;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                padding: 40px;
                                margin-top: 8px;
                                width: 100%;
                                // position: absolute;
                                img {
                                    width: 350px;
                                    height: 210px;
                                }
                                p {
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 18px;
                                    margin-top: 15px;
                                    color: #696873;
                                }
                            }
                            td {
                                font-family: Roboto;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 32px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: $heading;
                                vertical-align: middle;
                                border-top: 7px solid #f9f9ff !important;
                                padding: 12px 10px;
                                // text-align: center;
                                p {
                                    font-family: Roboto;
                                    font-size: 10px;
                                    font-weight: 400;
                                    line-height: 12px;
                                    letter-spacing: 0px;
                                    text-align: left;
                                    color: #9b9aa3;
                                }
                                .start-test {
                                    // width: 95px;
                                    height: 40px;
                                    color: #fff;
                                    border-radius: 4px;
                                    border: none;
                                    font-family: Roboto;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 32px;
                                    letter-spacing: 0em;
                                    background-color: $button-color;
                                }
                            }
                            .action {
                                cursor: pointer;

                                img {
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                                button {
                                    border: none;
                                    background: none;
                                }
                            }
                        }
                    }
                }
            }
            ul {
                list-style: none;
                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: #fff;
                    margin-top: 5px;
                    padding: 10px 15px;
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                    .topic-name {
                        // flex: 1;
                        width: 40%;
                    }
                    .topic-time {
                        // display: flex;
                        width: 20%;
                        p {
                            font-family: Roboto;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 32px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: $heading;
                        }
                    }
                    .last-taken {
                        display: flex;
                        width: 20%;
                        p {
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            display: flex;
                            align-items: center;
                            color: #9b9aa3;
                        }
                    }
                    .last-score {
                        width: 10%;
                        p {
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 20px;
                            line-height: 18px;
                            color: $dark-background;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-direction: column;
                            span {
                                font-family: Roboto;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 15px;
                                color: #9b9aa3;
                                margin-top: 2px;
                            }
                        }
                    }
                    .last-history {
                        display: flex;
                        width: 10%;
                        cursor: pointer;
                        p {
                            font-family: Lato;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: #9b9aa3;
                            margin-left: 5px;
                        }
                    }
                    .start {
                        button {
                            background-color: $button-color;
                            color: #fff;
                            border: none;
                            font-family: Roboto;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: 0px;
                            text-align: center;
                            padding: 6px 25px;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }
}

.quiz-details {
    margin-right: 0;
    margin-top: 0 !important;
    margin-bottom: 0;
    width: calc(100vw - 0px);
    min-height: calc(100% - 0rem);
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 8;
    background: #fff;
    &::before {
        background: transparent;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        content: "";
        width: calc(100vw - 0px);
    }

    .modal-content {
        height: 100%;
        border: 0;
    }

    .modal-body {
        padding: 0;
        background: #f6f6f6;
        height: 100%;
        .test-holder {
            width: 100%;
            background: #fff;
            height: 100%;
            padding: 0px 10px;
            padding-bottom: 100px;
            overflow: auto;
            position: relative;
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #e9e8e8;
            }
            .close-quiz {
                position: absolute;
                top: 15px;
                right: 15px;
                cursor: pointer;
            }
            .timer {
                width: 130px;
                height: 44px;
                background-color: #f5f5f5;
                float: right;
                p {
                    font-family: Roboto;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #000000;
                }
            }
            .question-div {
                h5 {
                    font-family: Roboto;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #000000;
                }
                h4 {
                    font-family: Roboto;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #000000;
                }
                ul {
                    list-style: none;
                    margin-top: 10px;
                    margin-right: -50px;
                    li {
                        width: 100%;
                        // display: flex;
                        // justify-content: center;
                        align-items: center;
                        .choice {
                            background: #8b7df230;
                            width: 29px;
                            height: 29px;
                            font-family: Roboto;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 32px;
                            letter-spacing: 0em;
                            text-align: center;
                            color: #000000;
                            border-radius: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .incorrect {
                                background: #ff7595;
                            }
                            .correct {
                                background: #8ec9b2;
                            }
                        }
                        .ans-color {
                            background-color: #5855d6;
                            color: #fff !important;
                            p {
                                color: #fff !important;
                            }
                            .choice {
                                color: #fff !important;
                            }
                        }
                        .option {
                            border-radius: 8px;
                            border: 1px solid #5855d6;
                            width: 67.5%;
                            min-height: 40px;
                            margin-top: 10px;
                            box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                            padding: 5px;
                            margin-left: 100px;
                            cursor: pointer;
                            margin-right: -10px;
                            color: #000000;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            p {
                                color: #000000;
                                padding: 5px;
                                span {
                                    color: #000000;
                                }
                                img {
                                    vertical-align: middle;
                                }
                            }
                        }

                        .option1 {
                            border-radius: 8px;
                            border: 1px solid #5855d6;
                            width: 67.5%;
                            min-height: 40px;
                            margin-top: 10px;
                            box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                            padding: 5px;
                            margin-left: 100px;
                            cursor: pointer;
                            color: #000000;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            p {
                                padding: 5px;
                                color: #000000;
                                span {
                                    color: #000000;
                                }
                                img {
                                    vertical-align: middle;
                                    &:hover {
                                        -ms-transform: scale(4); /* IE 9 */
                                        -webkit-transform: scale(4); /* Safari 3-8 */
                                        transform: scale(4);
                                        position: relative;
                                        /* right: -380px; */
                                        z-index: 9999;
                                    }
                                }
                            }
                        }
                    }
                }
                .fixed-prev {
                    position: fixed;
                    .next-quiz {
                        font-size: 28px;
                        position: fixed;
                        bottom: 50px;
                        right: 32%;
                        width: 10%;
                        color: #fff !important;
                        background-color: #5855d6;
                        padding: 10px;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .prev-quiz {
                        font-size: 28px;
                        position: fixed;
                        bottom: 50px;
                        left: 32%;
                        background-color: #5855d6;
                        width: 10%;
                        font-size: 14px;
                        color: #fff;
                        padding: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .next-quiz {
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #5855d6;
                    display: inline-block;
                    margin-left: 40px;
                }
                .prev-quiz {
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #5855d6;
                    display: inline-block;
                }
            }

            .desc {
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left;
                color: #555555;
                margin-top: 10px;
            }
            .start-btn {
                color: white;
                background-color: $button-color;
                border: none;
                border-radius: 4px;
                padding: 10px 40px;
            }

            .quiz-complete {
                p {
                    font-family: Roboto;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: 0px;
                    text-align: left;
                }
                small {
                    margin-top: 10px;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                }
                .score {
                    margin-top: 20px;
                    font-family: Roboto;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 80px;
                    letter-spacing: 0px;
                    text-align: left;
                }
                .start-btn {
                    color: white;
                    background-color: $button-color;
                    border: none;
                    border-radius: 4px;
                    padding: 10px 40px;
                    margin-top: 15px;
                }
            }
        }
        .correct {
            background-color: #5ebb97;
            color: #fff !important;
            p {
                color: #fff !important;
                span {
                    color: #fff !important;
                }
            }
        }
        .incorrect {
            background-color: #e8325d;
            color: #fff !important;
            p {
                color: #fff !important;
                span {
                    color: #fff !important;
                }
            }
        }
    }
}

.dropdown-container {
    width: 100%;
    position: relative;
    .dropdown-header {
        margin-top: 12px;
        border: 1px solid $chart-color;
        border-radius: 4px;
        padding: 0.5rem 0.9rem;
        color: #696873;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        background-image: url("../../assets/arr.png");
        background-position: center right;
        background-repeat: no-repeat;
        background-size: 27px;
        cursor: pointer;
        white-space: pre-wrap;
    }
    .dropdown-list-container {
        position: absolute;
        z-index: 100;
        top: 50px;
        width: 100%;
        .dropdownlist {
            padding: 0;
            margin: 0;
            max-height: 200px;
            overflow: auto;
            // padding-left: 1em;
            margin-left: 1px;
            background: #ffffff;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            font-family: "Roboto";
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #e9e8e8;
            }
            .listItem {
                list-style: none;
                padding: 10px 0 10px 10px;
                cursor: pointer;
                border-bottom: 1px solid #0000001a;
                &:hover {
                    background-color: #ced4da;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .quiz-details {
        width: 100%;
        .modal-body {
            .test-holder {
                margin-top: -8px;
                overflow-x: hidden;
                padding: 10px 0px;
                .close-quiz {
                    position: absolute;
                    top: 25px;
                    right: 10px;
                    cursor: pointer;
                    z-index: 3;
                }

                .question-div {
                    ul {
                        margin-right: -25px;
                        li .option {
                            margin-left: 40px;
                            width: 75%;
                        }
                        li .option1 {
                            margin-left: 40px;
                            width: 75%;
                        }
                    }
                    .next-quiz {
                        margin-left: 30px;
                    }
                    .fixed-prev {
                        // position: fixed;
                        .next-quiz {
                            font-size: 28px;
                            position: fixed;
                            bottom: 35px;
                            right: 10%;
                            width: 30%;
                            color: #fff !important;
                            background-color: #5855d6;
                            padding: 10px;
                            font-size: 14px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .prev-quiz {
                            font-size: 28px;
                            position: fixed;
                            bottom: 35px;
                            left: 60px;
                            background-color: #5855d6;
                            width: 30%;
                            font-size: 14px;
                            color: #fff;
                            padding: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }

                .quiz-complete {
                    margin-bottom: 5rem;
                }
            }
        }
    }
    .mobile-drawer {
        position: relative;
        .close-explaination {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    .practise-test-holder {
        width: 93%;
        margin-left: 10px;
        margin-top: 0px;
        margin-bottom: 20px;
        .practise-header {
            h3 {
                //styleName: h4;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;
            }
        }
        .test-dashboard {
            flex-wrap: wrap;
            margin-left: -15px;
            margin-right: -15px;
            width: unset;
            margin-top: 10px;
            padding: 12px 20px;
            border-radius: 0px;
            .select-test {
                .dropdown-container {
                    width: 80%;
                    .dropdown-header {
                        padding-right: 0.5rem;
                    }
                }
            }
            .progres-test {
                margin-top: 15px;
                flex-wrap: nowrap;
                padding: 0px 10px;
                .mobile-progress {
                    width: 100%;
                    min-height: 190px;
                    padding: 0px 10px;
                }
                .card {
                    min-width: 185px;
                    width: 220px;
                    height: 165px;
                    margin-right: 15px;
                    &:nth-last-child {
                        margin-right: 0px;
                    }
                    h1 {
                        height: 48px;
                    }
                }
                .mr {
                    margin-right: 20px;
                }
                .empty-list {
                    p {
                        font-size: 13px;
                    }
                }
            }
        }
        .empty-test {
            padding-bottom: 80px;
            padding-top: 35px;
            width: unset;
            margin-right: -13px;
            margin-left: -13px;
            .no-test {
                p {
                    padding: 10px 30px;
                    text-align: justify;
                    font-size: 13px;
                }
            }
        }
        .test-details {
            .subject-btn-list {
                width: 100%;
                overflow: auto;
                .subject-btn {
                    width: 54%;
                    flex: none !important;
                }
            }
        }
        .test-wise-details {
            margin-left: -13px;
            margin-right: -13px;
            ul {
                li {
                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background-color: #fff;
                        margin-top: 5px;
                        padding: 10px 15px;
                        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                        .topic-name {
                            // flex: 1;
                            width: 20%;
                        }
                        .topic-time {
                            display: flex;
                            width: 20%;
                            p {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                font-family: Lato;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                                letter-spacing: 0px;
                                text-align: left;
                                color: $dark-background;
                                margin-right: 20px;
                                span {
                                    color: #9b9aa3;
                                    //styleName: Caption;
                                    font-family: Roboto;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 15px;
                                    letter-spacing: 0px;
                                }
                            }
                        }
                        .last-taken1 {
                            h3 {
                                font-family: Roboto;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 18px;
                                letter-spacing: 0px;
                                text-align: left;
                                color: #484750;
                            }
                            p {
                                font-family: Roboto;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 15px;
                                letter-spacing: 0px;
                                text-align: left;
                                color: #9b9aa3;
                            }
                        }

                        .last-score {
                            width: 10%;
                            p {
                                font-family: Lato;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 20px;
                                line-height: 18px;
                                color: $dark-background;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-direction: column;
                                span {
                                    font-family: Roboto;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 12px;
                                    line-height: 15px;
                                    color: #9b9aa3;
                                    margin-top: 2px;
                                }
                            }
                        }
                        .last-history {
                            display: flex;
                            width: 10%;
                            cursor: pointer;
                            p {
                                font-family: Lato;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                                letter-spacing: 0px;
                                text-align: left;
                                color: #9b9aa3;
                                margin-left: 5px;
                            }
                        }
                        .start {
                            button {
                                padding: 8px 25px !important;
                                background-color: $button-color;
                                color: #fff;
                                border: none;
                                font-family: Roboto;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 18px;
                                letter-spacing: 0px;
                                text-align: center;
                                border-radius: 4px;
                            }
                        }
                    }
                    padding: 15px 15px !important;
                }
            }
        }
    }
    .test-info {
        padding: 15px;
        h3 {
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            color: #484750;
        }
        p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            text-align: center;
        }
        .test-ques {
            padding: 20px 55px;
            p {
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 24px;
                color: $dark-background;
                margin-left: 20px;
                display: inline-block;
                span {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #9b9aa3;
                }
            }
        }
        .close-details {
            position: absolute;
            right: 15px;
            top: 20px;
        }
        .history {
            list-style: none;
            padding: 10px 60px;
            li {
                font-family: Roboto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #dddde2;
                padding: 5px;
                p {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: #696873;
                }
            }
        }
    }
}

@media screen and (max-width: 700px) and (orientation: landscape) {
    .quiz-details .modal-body .test-holder .question-div .fixed-prev {
        display: flex;
        justify-content: center;
        position: unset !important;
        .prev-quiz {
            position: unset !important;
        }
        .next-quiz {
            position: unset !important;
        }
    }
}
