@import "../../App.scss";

.w-95 {
    width: 95% !important;
}
.dashboard-container1 {
    margin-bottom: 10px;
    margin-left: 5px;
    .slider-dashboard {
        margin-right: 25px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        .static-holder {
            background-color: #fff;
            // margin-top: 21px;
            padding: 25px;
            padding-bottom: 15px;
            margin-right: 14px;
            height: 355px;
            .cards-dashboard {
                display: flex;
                .cards-column {
                    flex: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .topic-progress {
                        width: 200px;
                        flex: 1.5;
                        margin-left: 50px;
                        .progress-text {
                            font-family: Lato;
                            font-weight: 400;
                            font-size: 64.766px;
                            line-height: 78px;
                            text-align: center;
                            color: $dark-background;
                        }
                        span {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16.726px;
                            line-height: 20px;
                            color: #696873;
                            text-align: center;
                        }
                    }
                    .chart-cards {
                        flex: 1;
                        border-radius: 5px;
                        margin-bottom: 10px;
                        height: 95px;
                        text-align: center;
                        // transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
                        position: relative;
                        display: flex;
                        z-index: 1;
                        cursor: pointer;
                        margin-left: -25px;
                        .current-subjects {
                            flex: 2;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #f7fafa;
                            p {
                                font-family: Roboto;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                                letter-spacing: 0px;
                                color: #696873;
                                text-align: left;
                            }
                        }
                        h4 {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 40px;
                            line-height: 48px;
                            background-color: $chart-color;
                            font-family: Lato;
                            color: #fff;
                            flex: 1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 4px;
                        }

                        h5 {
                            margin-top: 10px;
                            font-size: 39px;
                            font-weight: 700;
                            font-family: "Roboto", sans-serif;
                            text-align: center;

                            img {
                                float: right;
                                margin-top: -3px;
                            }
                        }
                    }
                }
                .cards-column-details {
                    flex: 1;
                    h3 {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 23px;
                        color: $dark-background;
                        padding-bottom: 3px;
                        padding-top: 0;
                    }
                    h4 {
                        max-height: inherit;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-family: Roboto;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px;
                        letter-spacing: 0px;
                        text-align: left;
                        margin-top: 15px;
                    }
                    h5 {
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #000000;
                    }
                    p {
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #696873;
                        max-height: 70px;
                        margin-top: 10px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    .primary-button {
                        position: fixed;
                        z-index: 8888;
                        width: 225px;
                        text-align: left;
                        padding-left: 12px;
                        margin-top: 30px;
                        border: 1px solid $button-color !important;
                        background-color: $button-color;
                        background-image: none !important;
                        height: 40px;
                        color: #fff;
                        border-radius: 4px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0px;
                        text-align: left;
                        display: flex;
                        justify-self: center;
                        align-items: center;
                        img {
                            display: inline-block;
                        }
                    }
                }
            }
            h3 {
                font-size: 21px;
                font-weight: 400;
                font-family: "Roboto", sans-serif;
                padding-top: 15px;
                padding-bottom: 13px;
                span {
                    font-weight: 600;
                }
            }
        }
    }
    .engagment-section {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 0px;
        border-radius: 4px;
        margin-left: 0;
        .engagement-score-main {
            background-color: #fff;
            height: 100%;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            h3 {
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 23px;
                letter-spacing: 0px;
                text-align: left;
                color: $dark-background;
            }
            .engagment-points {
                display: flex;
                flex-direction: column;
                justify-content: center;
                // padding-left: 21px;
                text-align: center;
                background-color: #fff;
                h1 {
                    font-family: Lato;
                    font-size: 128px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 154px;
                    letter-spacing: -5px;
                    text-align: center;
                    color: $dark-background;
                }
                p {
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #696873;
                }
            }
            .program-rank {
                padding-top: 40px;
                margin-left: 40px;
                width: 50%;
                .chart-cards {
                    flex: 1;
                    cursor: pointer;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    height: 103px;
                    text-align: center;
                    // transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
                    position: relative;
                    display: flex;
                    z-index: 1;
                    .current-subjects {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: #f7fafa;
                        padding: 20px;
                        p {
                            font-family: Roboto;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0px;
                            color: #696873;
                            text-align: center;
                        }
                    }
                    h4 {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 40px;
                        line-height: 48px;
                        background-color: $chart-color;
                        color: #fff;
                        font-family: Lato;
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 20px;
                        border-radius: 4px;
                    }

                    h5 {
                        margin-top: 10px;
                        font-size: 39px;
                        font-weight: 700;
                        font-family: "Roboto", sans-serif;
                        text-align: center;

                        img {
                            float: right;
                            margin-top: -3px;
                        }
                    }
                }
            }
        }
        .engagement-graph {
            background-color: #fff;
            height: 100%;
            position: relative;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            h3 {
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 23px;
                letter-spacing: 0px;
                text-align: left;
                color: $dark-background;
                small {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $button-color;
                    float: right;
                    cursor: pointer;
                    img {
                        vertical-align: middle;
                    }
                }
            }
            .engagement-score {
                h3 {
                    font-family: Lato;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 58px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: $dark-background;
                }
                p {
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #696873;
                }
            }
            .engagement-chart {
                .highcharts-data-label text {
                    fill: $darker-background !important;
                    color: $darker-background !important ;
                    font-size: 14px !important;
                    font-weight: 600 !important;
                    font-family: Lato !important;
                }
                .highcharts-axis-line {
                    stroke: #ecc30b !important;
                }
                .highcharts-point {
                    fill: $chart-color !important;
                }
                .engagement-update {
                    position: absolute;
                    top: 40%;
                    z-index: 1;
                    left: 0px;
                    /* left: 0; */
                    /* right: 0; */
                    width: 100%;
                    text-align: center;
                }
            }
            .graph-note {
                text-align: right;
                padding-right: 10px;
                span {
                    font-size: 12px;
                    font-style: italic;
                    color: #b1a6a6;
                }
            }
        }
    }
    .content-consume {
        .legand-box {
            display: flex;
            justify-content: space-between;
            background-color: #fff;
            .legend-circle {
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }
            h3 {
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 23px;
                letter-spacing: 0px;
                text-align: left;
                color: $dark-background;
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: left;
                    color: #696873;
                }
            }
        }
        .legands {
            width: 285px;
            list-style: none;
            padding: 8px;
            background-color: #f7f7fa;
            border-radius: 4px;
            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-family: Roboto;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                color: #696873;
                font-size: 12px;
                .div-width {
                    width: 12px;
                    height: 12px;
                    border-radius: 50px;
                }
            }
        }

        .subject-consume {
            background-color: #fff;
            height: 100%;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            // .highcharts-axis-line {
            //     display: none !important;
            // }
            .student-trend {
                margin-top: 15%;
                .login-percent {
                    text-align: center;
                    h4 {
                        color: #4eb966;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 47px;
                        line-height: 65px;
                    }
                    span {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 16px;
                        color: #696873;
                    }
                }
                .circle {
                    border-radius: 50%;
                    width: 55px;
                    height: 55px;
                    color: #fff;
                    margin-right: 5px;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-weight: bold;
                }
            }
            .highcharts-label text {
                font-family: Lato;
                font-size: 14px !important;
                font-weight: 700 !important;
                fill: #696873 !important;
            }
            .subject-chart {
                margin-top: 15px;
            }
            .engagement-update {
                position: absolute;
                top: 50%;
                z-index: 1;
                left: 0px;
                /* left: 0; */
                /* right: 0; */
                width: 100%;
                text-align: center;
            }
        }
        .month-consume {
            background-color: #fff;
            height: 100%;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            .highcharts-legend-box {
                fill: #f7f7fa !important;
                border-radius: 4px;
            }
            .highcharts-axis-line {
                display: none !important;
            }
            .engagement-update {
                position: absolute;
                top: 50%;
                z-index: 1;
                left: 0px;
                /* left: 0; */
                /* right: 0; */
                width: 100%;
                text-align: center;
            }
            .highcharts-legend-item text {
                font-family: Roboto;
                font-style: normal;
                font-weight: 400 !important;
                line-height: 15px;
                color: #696873 !important;
            }
            .subject-chart {
                margin-top: 15px;
            }
        }
        .subject-consume1 {
            height: 100%;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            .legend-status {
                display: flex;
                padding: 0px 20px;
                padding-top: 7px !important;
                padding-top: 0px;
                background-color: #fff;
                .legend {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #696873;
                    margin-right: 12px;
                    .legend-circle {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                    }
                }
            }
            .progres-test {
                // margin-top: 15px;
                // position: relative;
                padding: 20px;
                background-color: #fff;
                .card {
                    border: none;
                    border-radius: 4px;
                    height: 225px;
                    margin-top: 1px;
                    margin-left: 2px;
                    border: none;
                    width: 95%;
                    // margin-right: 10px;
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                    border-radius: 4px;
                    cursor: default;
                    .card-body {
                        .card-overlay {
                            position: absolute;
                            z-index: 3;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    h1 {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        color: #222222;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }

                    .status {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 90%;
                        margin-top: 15px;
                        h2 {
                            font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 24px;
                            line-height: 29px;
                            color: #ecc30b;
                        }
                        h3 {
                            font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 17px;
                            color: #696873;
                            margin-left: -12px;
                        }
                    }
                }

                .slick-list {
                    padding-bottom: 25px;
                }
                .slick-track {
                    display: flex !important;
                    align-items: stretch;
                }

                .slick-slide {
                    height: 210px !important;
                }
                .slick-slide {
                    // width: 175px!important;
                    margin-right: 1px;
                    &:last-child {
                        margin-right: 40px;
                    }
                }
                .slick-disabled {
                    opacity: 0% !important;
                }
                .slick-next {
                    height: 0;
                    right: 18px;
                    top: 100px;
                    width: 0;
                    position: absolute;
                    cursor: pointer;
                    &:before {
                        font-size: 0px;
                        opacity: 0;
                        display: none;
                    }
                }
                .slick-prev {
                    height: 0;
                    left: -18px;
                    top: 100px;
                    width: 0;
                    position: absolute;
                    z-index: 1;
                    cursor: pointer;
                    &:before {
                        opacity: 0;
                        display: none;
                        font-size: 0px;
                    }
                }

                .active-subject {
                    // background-color: $dark-background !important;
                    border: 2px solid $button-color;
                    border-color: $button-color;
                    position: relative;
                }
            }

            .highcharts-label text {
                font-family: Lato;
                font-size: 14px !important;
                font-weight: 600 !important;
                fill: $darker-background !important;
            }
            // Circular progress bar
            .circular-progresss {
                position: relative;
                width: 300px;
                .circular-progress2 {
                    width: 300px;
                    position: absolute;
                    top: 12.8%;
                    left: 0%;
                }
            }
        }

        .engagement-graph {
            background-color: #fff;
            height: 100%;
            position: relative;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            h3 {
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 23px;
                letter-spacing: 0px;
                text-align: left;
                color: $dark-background;
                small {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $button-color;
                    float: right;
                    cursor: pointer;
                    img {
                        vertical-align: middle;
                    }
                }
            }
            .engagement-score {
                h3 {
                    font-family: Lato;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 58px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #4eb966;
                }
                p {
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #696873;
                }
            }
            .engagement-chart {
                .highcharts-data-label text {
                    fill: $darker-background !important;
                    color: $darker-background !important ;
                    font-size: 14px !important;
                    font-weight: 600 !important;
                    font-family: Lato !important;
                }
                .highcharts-axis-line {
                    stroke: #efb118 !important;
                }
                .highcharts-point {
                    fill: #e56e25 !important;
                }
                .engagement-update {
                    position: absolute;
                    top: 40%;
                    z-index: 1;
                    left: 0px;
                    /* left: 0; */
                    /* right: 0; */
                    width: 100%;
                    text-align: center;
                }
            }
            .graph-note {
                text-align: right;
                padding-right: 10px;
                span {
                    font-size: 12px;
                    font-style: italic;
                    color: #b1a6a6;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .dashboard-container1 {
        margin-bottom: 25px;
        margin-top: -25px;
        margin-left: 0px;
        margin-right: 0px;
        .pr-md-2 {
            padding-right: 0px !important;
            margin-top: 10px;
        }
        .slider-dashboard {
            margin-right: 0px;
            .static-holder {
                height: 745px;
                margin-right: 0px;
                .cards-dashboard {
                    flex-wrap: wrap;
                    .cards-column-details {
                        flex: unset;
                    }
                    .cards-column {
                        flex-wrap: wrap;
                        margin-top: 30px;
                        flex-direction: column;
                        align-items: unset;
                        .topic-progress {
                            width: 65%;
                            margin-left: 50px;
                        }
                        .chart-cards {
                            margin-left: 0px;
                        }
                    }
                    .cards-column-details .primary-button {
                        position: relative;
                        top: 30px !important;
                        margin-top: 0px;
                        margin-bottom: 20px;
                        font-size: 13px;
                    }
                }
            }
        }

        .engagment-section {
            .engagement-score-main {
                border-radius: 0px;
                .engagment-points {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
                .program-rank {
                    margin-left: 0px;
                    padding-top: 0px;
                    width: 100%;
                }
            }
            .engagement-graph {
                border-radius: 0px;
                .engagement-score {
                    margin-top: 20px;
                }
            }
        }
        .content-consume {
            .row {
                margin-left: 0px !important;
                margin-right: 0px !important;
                .col-12 {
                    padding-left: 0px !important;
                }
            }
            .legand-box {
                .legands {
                    width: 105px;
                }
            }
            .month-consume {
                border-radius: 0px;
            }
            .subject-consume {
                border-radius: 0px;
                padding: 12px;
            }
        }
    }
    .mobile-progress {
        height: 230px;
    }
    .dashboard-container1 .content-consume .subject-consume1 .progres-test .card {
        width: 65%;
        margin-right: 10px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .dashboard-container1 .slider-dashboard {
        margin-right: 15px;
        .static-holder {
            padding: 18px;
            .cards-dashboard .cards-column .topic-progress {
                width: 185px;
                flex: 1.5 1;
                margin-left: 10px;
            }
        }
    }
    .pl1 {
        padding-right: 0px !important;
    }
    .dashboard-container1 .engagment-section {
        .engagement-score-main {
            height: 70%;
        }
        .engagement-graph {
            height: 70%;
            .engagement-score {
                margin-top: -140px;
            }
        }
    }
}
@media (min-width: 1400px) {
    .dashboard-container1 .slider-dashboard .static-holder .cards-dashboard .cards-column .topic-progress {
        width: 250px;
    }
}

@media (min-width: 1600px) {
    .dashboard-container1 .slider-dashboard .static-holder .cards-dashboard .cards-column .topic-progress {
        width: 280px;
    }
}
