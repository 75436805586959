@import "../../../App.scss";

.cursor-pointer {
    cursor: pointer;
}
.subtopics-module {
    min-height: 80vh;
    margin-left: -320px;
    display: flex;
    // margin-top: 2px;
    .collapse-topics {
        width: 21.5%;
        padding: 15px 17px;
        padding-right: 10px;
        h1 {
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            color: #222222;
            margin-left: 20px;
        }
        .accordion .card-header:after {
            content: url("../../../assets/arrow-up.svg");
            width: 15px;
            margin-right: 15px;
            float: left;
            margin-top: -19px;
            cursor: pointer;
            // background-image: url('../../images/arrow-down.svg');
        }
        .accordion .card-header.collapsed:after {
            /* symbol for "collapsed" panels */
            content: url("../../../assets/arrow-down.svg");
            width: 15px;
            margin-right: 15px;
            margin-top: -19px;
            cursor: pointer;
        }
        .display {
            &::after {
                display: none;
            }
        }
        .accordion {
            .card {
                padding: 5px 0px;
                border: none;
                &:not(:last-of-type) {
                    border-bottom: none;
                }
                a {
                    color: #696873;
                }
            }
            .card-header {
                background-color: #fff;
                border-bottom: none;
                margin-bottom: -10px;
                .card-title {
                    p {
                        font: normal normal 600 14px/18x Roboto;
                        color: #272727;
                    }
                }
            }
            .card-body {
                padding: 0rem;
            }
        }
        .content-holder {
            list-style: none;
            max-height: calc(100vh - 12rem);
            overflow-y: auto;
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #e9e8e8;
            }
            &:hover .resource:hover {
                background: #edf3f3;
            }
            .resource {
                display: flex;
                min-height: 30px;
                width: 100%;
                padding: 5px;
                position: relative;
                overflow-x: hidden;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 8px;
                cursor: pointer;
                &:hover {
                    background: #ddd;
                }
                .recent-title {
                    margin-left: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    /* align-items: baseline; */
                    h1 {
                        text-align: left;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        opacity: 1;
                        color: #000000;
                        margin-left: 0px;
                    }
                }
            }
        }
    }
    .manage-subtopics {
        margin-left: 20px;
        // background: #fff;
        // padding: 18px;
        margin-top: 0px;
        position: relative;
        // border-bottom: 2px solid $chart-color;
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        // padding-left: 39px;
        // padding-bottom: 68px;
        h1 {
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            color: #222222;
        }
        .select-qes-list {
            .content-header {
                position: relative;
                border-bottom: 2px solid $chart-color;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                background-color: #fff;
                padding: 6px;
                .content-heading {
                    ul {
                        list-style: none;
                        margin-top: 20px;
                        li {
                            display: flex;
                            // text-align: center;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            color: $chart-color;
                        }
                    }
                    ul li div {
                        color: $chart-color;
                    }
                }
            }
            .program-list {
                list-style: none;
                margin-top: 8px;
                li {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    padding: 13px 22px;
                    cursor: pointer;
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                    background-color: #fff;
                    margin-bottom: 8px;
                    position: relative;
                    .program-overlay {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        opacity: 0;
                        z-index: 4;
                        top: 0px;
                        width: 85%;
                        height: 56px;
                    }

                    h3 {
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 24px;
                        color: #696873;
                        font-family: Lato;
                    }
                    p {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                        .react-switch-handle {
                            height: 12px !important;
                            width: 12px !important;
                            top: 2px !important;
                            left: 1px;
                        }
                    }
                    .program-name {
                        width: 45%;
                        img {
                            display: inline-block;
                            vertical-align: middle;
                        }
                        p {
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                    div {
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            width: 20%;
                        }
                        img {
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 10px;
                        }
                    }
                    div:nth-child(6) {
                        width: 20%;
                        text-align: center;
                    }
                }
            }
        }

        .subject-header {
            .add-subject {
                padding: 0;
                margin: 0;
                width: 100% !important;
                .content-heading {
                    .content-header {
                        background: #fff;
                        padding: 18px;
                        margin-top: 8px;
                        padding-bottom: 5px;
                        position: relative;
                        border-bottom: 2px solid $chart-color;
                        // box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
                        padding-top: 33px;
                    }
                    .program-list {
                        list-style: none;
                        margin-top: 8px;
                        li {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            padding: 13px 22px;
                            cursor: pointer;
                            // box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
                            background-color: #fff;
                            // margin-bottom: 8px;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                            position: relative;
                        }
                    }
                }
                .fromtoTime {
                    select {
                        border: 1px solid $chart-color;
                    }
                }
                .recommend-checkbox {
                    margin-top: 20px;
                    label {
                        width: auto;
                        input[type="checkbox"] {
                            cursor: pointer;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            outline: 0;
                            background: #ffffff;
                            height: 18px;
                            width: 18px;
                            border: 1px solid #9b9aa3;
                            border-radius: 4px;
                            &:checked {
                                background: $button-color;
                            }
                            &:after {
                                content: "";
                                position: relative;
                                left: 27%;
                                top: 5%;
                                width: 50%;
                                height: 70%;
                                border: solid #fff;
                                border-width: 0 2px 2px 0;
                                transform: rotate(45deg);
                                display: none;
                            }
                            &:checked:after {
                                display: block;
                            }
                        }
                        input {
                            cursor: pointer;
                            margin: 0 12px 0 0;
                            vertical-align: middle;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
                .video-box {
                    box-shadow: 0px 0px 8px #aaaaaa7d;
                    border-radius: 7px;
                    padding: 10px 20px 20px;
                    background-color: #fff;
                }
            }
            .subject-btn {
                margin-top: 0px;
                li {
                    display: inline-block;
                    button {
                        background: $button-color;
                        border: none;
                        width: 134px;
                        height: 40px;
                        border-radius: 4px;
                        color: #fff;
                        font-weight: bold;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        line-height: 18px;
                        margin-left: 5px;
                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            ul {
                list-style: none;
                margin-top: 20px;
                li {
                    display: flex;
                    // text-align: center;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: $chart-color;
                    cursor: default;
                    div:first-child {
                        width: 10%;
                        padding-left: 32px;
                        color: $chart-color;
                    }
                    div:nth-child(2) {
                        width: 30%;
                        color: $chart-color;
                        margin-left: 35px;
                    }
                    div {
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            width: 20%;
                            color: $chart-color;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .subject-list {
            list-style: none;
            margin-top: 7px;
            font-family: Roboto;
            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                padding: 13px 22px;
                cursor: pointer;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                background-color: #fff;
                margin-bottom: 8px;
                cursor: default;
                h3 {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 14px;
                    color: #696873;
                    font-family: Roboto;
                }
                p {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #696873;
                    .react-switch-handle {
                        height: 12px !important;
                        width: 12px !important;
                        top: 2px !important;
                    }
                }
                .subject-name {
                    width: 35%;
                    margin-left: -10px;
                    img {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    p {
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
                .subject-num {
                    width: 10%;
                    padding-left: 30px;
                    img {
                        display: inline-block;
                        vertical-align: bottom;
                        margin-left: -35px;
                        cursor: grab;
                    }
                    p {
                        display: inline-block;
                        margin-left: 12px;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 24px;
                        color: #696873;
                        font-family: Lato;
                    }
                }
                div {
                    &:nth-child(3),
                    &:nth-child(4) {
                        width: 20%;
                    }
                    img {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                }
                div:nth-child(5) {
                    width: 20%;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
}

.add-content-wrapper {
    background-color: inherit !important;
    border: none !important;
    box-shadow: none !important;
    .subject-header {
        background: #fff;
        padding: 18px;
        margin-top: 8px;
        padding-bottom: 5px;
        position: relative;
        border-bottom: 2px solid $chart-color;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }
}
.selected-question {
    // padding-right: 137px;
    width: 88%;
    margin-left: 45px;
    .ques-header {
        margin-bottom: 10px;
        button {
            position: relative;
            background: $button-color;
            border: none;
            width: 168px;
            height: 40px;
            border-radius: 4px;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 18px;
            .arrow-right,
            .long-arrow-right {
                transform: rotate(135deg);
            }
            .arrow-right,
            .arrow-left,
            .long-arrow-right,
            .long-arrow-left {
                display: block;
                width: 7px;
                height: 7px;
                border-top: 2px solid #fff;
                border-left: 2px solid #fff;
                margin-left: 24px;
                position: absolute;
                right: 20px !important;
            }
            img {
                width: 14px;
            }
        }
    }
}
.selected-ques {
    width: 70%;
    margin-left: 20px;
    .ques-header {
        margin-bottom: 10px;
        button {
            position: relative;
            background: $button-color;
            border: none;
            width: 168px;
            height: 40px;
            border-radius: 4px;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 18px;
            .arrow-right,
            .long-arrow-right {
                transform: rotate(135deg);
            }
            .arrow-right,
            .arrow-left,
            .long-arrow-right,
            .long-arrow-left {
                display: block;
                width: 7px;
                height: 7px;
                border-top: 2px solid #fff;
                border-left: 2px solid #fff;
                margin-left: 24px;
                position: absolute;
                right: 20px !important;
            }
            img {
                width: 14px;
            }
        }
    }
}
.selected-content-question {
    width: 70%;
    margin-left: 45px;
}
.subtopic-video {
    background: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.question-select-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #9b9aa3;
}

//Preview Modal
.preview-title1 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.preview-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    width: 100%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.prevData {
    .card {
        display: flex;
        margin-bottom: 0px;
        position: relative;
        vertical-align: top;
        padding: 18px;
        width: 100%;
        flex-direction: row;
        border-radius: 8px;
        box-shadow:
            0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        .card-image {
            img {
                border-radius: 4px;
            }
        }
        .card-description {
            padding: 0px 12px;
            width: 75%;
            p {
                font: normal normal normal 16px/21px Roboto;
                letter-spacing: 0px;
                color: #877eec;
            }
            h2 {
                font: normal normal bold 15px/15px Roboto;
                letter-spacing: 0px;
                color: #2b2b2b;
                max-height: 30px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            small {
                color: #a5a5a5;
                max-height: 60px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                font: normal normal normal 12px/15px Roboto;
                letter-spacing: 0px;
                color: #707070;
            }
        }
    }
}

$bg-color: #6457e8; //container background-color;
$basic-dark-color: #ffffff; //color of the spinner
$border-width: 4px; //width of spinners border
$basic-spinner-dimensions: 125px; //width and height of spinner
$main-spinner-dimensions: $basic-spinner-dimensions - $border-width * 2; //width and height of bigger circle
$small-spinner-dimensions: $main-spinner-dimensions * 0.7; //width and height of smaller circle

/* COMMON STYLES: YOU DON'T NEED THEM */

.div-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    bottom: 0px;
    backdrop-filter: blur(1px) brightness(35%);
    -webkit-backdrop-filter: blur(1px) brightness(35%);
    z-index: 99;
    .progress-upload {
        position: absolute;
        left: 35%;
        top: 50%;
        transform: translate(-50px, -50%);
        width: 500px;
        p {
            color: #fff;
            margin-bottom: 5px;
        }
        .progress {
            height: 1.5rem;
        }
    }
    .container1 {
        //   width: 960px;
        //   margin: 70px auto 0px auto;
        text-align: center;
        z-index: 999;
        h1 {
            font: {
                family: "Lato", sans-serif;
                size: 40px;
            }
            color: $basic-dark-color;
            margin-bottom: 20px;

            & + a {
                color: $basic-dark-color;
                font: {
                    family: "Lato", sans-serif;
                    size: 20px;
                }
                text-decoration: none;
            }

            &:before {
                content: "{";
                display: inline-block;
                padding-right: 20px;
            }

            &:after {
                content: "}";
                display: inline-block;
                padding-left: 20px;
            }
        }

        .spinners1 {
            margin-top: 50px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .spinner-block1 {
                width: $basic-spinner-dimensions;
                text-align: center;
                margin-right: 83px;

                &:nth-child(5n) {
                    margin-right: 0px;
                }

                h2 {
                    font: {
                        family: "Pacifico", cursive;
                        size: 17px;
                    }
                    color: $basic-dark-color;
                    margin-bottom: 20px;
                }
            }
        }
        .spinner1 {
            // position: relative;
            width: $basic-spinner-dimensions;
            height: $basic-spinner-dimensions;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50px, -50%);
            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
                border-width: 4px;
                border-style: solid;
                border-radius: 50%;
            }
        }

        /* spinner-1 styles */
        .spinner1.spinner-1 {
            @keyframes rotate-animation {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }

            @keyframes anti-rotate-animation {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(-360deg);
                }
            }

            &:before {
                width: $main-spinner-dimensions;
                height: $main-spinner-dimensions;
                border-bottom-color: $basic-dark-color;
                border-right-color: $basic-dark-color;
                border-top-color: rgba($basic-dark-color, 0);
                border-left-color: rgba($basic-dark-color, 0);
                top: 0px;
                left: 0px;
                animation: rotate-animation 1s linear 0s infinite;
            }

            &:after {
                width: $small-spinner-dimensions;
                height: $small-spinner-dimensions;
                border-bottom-color: $basic-dark-color;
                border-right-color: $basic-dark-color;
                border-top-color: rgba($basic-dark-color, 0);
                border-left-color: rgba($basic-dark-color, 0);
                top: calc(($main-spinner-dimensions - $small-spinner-dimensions) / 2);
                left: calc(($main-spinner-dimensions - $small-spinner-dimensions) / 2);
                animation: anti-rotate-animation 0.85s linear 0s infinite;
            }
        }
    }
    .change-pos {
        position: absolute;
        left: 48%;
        top: 65%;
        transform: translate(-50px, -65%);
        color: $button-color;
    }
}
