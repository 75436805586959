@import "../../App.scss";

.users-module {
    font-family: Roboto;
    font-style: normal;
    width: 90%;
    margin-left: 5px;
    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        color: #484750;
    }
    .users-button {
        button {
            margin-top: 5px;
            background: #f7f7fa;
            border: 1px solid #dddde2;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            // border: none;
            padding: 7px;
            min-width: 120px;
            border-radius: 4px;
        }
        .active-btn {
            background: $dark-background;
            border: none;
            color: #fff;
            padding: 7px;
            min-width: 120px;
            font-weight: bold;
        }
    }
    .users-details {
        background-color: #fff;
        padding: 24px 40px;
        margin-top: 8px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .note-line {
            position: absolute;
            right: 65px;
            text-align: center;
            top: 12px;
            font-style: normal;
            font-weight: normal;
            font-size: 80%;
            line-height: 15px;
            color: #9b9aa3;
        }
        p {
            font-weight: bold;
            font-size: 24px;
            line-height: 24px;
            color: $dark-background;
            text-align: center;
            font-family: Lato;
            span {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                font-family: Roboto;
            }
        }
        h2 {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $button-color;
            cursor: pointer;
            margin-top: 10px;
        }
    }
    .users-header {
        background: #fff;
        padding: 25px 16px;
        margin-top: 8px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 56px;
        min-height: 131px;

        button {
            border: none;
            border-radius: 4px;
            background: $button-color;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            width: 200px;
            height: 40px;
            img {
                vertical-align: middle;
                width: 16px;
                height: 16px;
                margin-right: 15px;
            }
        }
        .has-search {
            position: relative;
            .form-control-feedback {
                position: absolute;
                z-index: 5;
                display: block;
                width: 2.375rem;
                height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: #aaa;
                margin-top: 3px;
                width: 24px;
                margin-left: 5px;
            }

            .form-control {
                padding-left: 1.9rem;
            }
            #search {
                background-color: #f7f7fa;
                border: none;
                &::-webkit-input-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &::-moz-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &:-ms-input-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &::placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
            }
        }
        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
        }
        select {
            background-color: #f2f2f2;
            border: none;
            background-image: url("../../assets/select-indicator.svg");
            background-position: 85% center;
            &:focus {
                box-shadow: none !important;
                border: none;
            }
        }
    }
    .table-admin {
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        background: #fff;
        margin-top: -60px;
        .table {
            margin-bottom: 0px;
            thead {
                hr {
                    margin-bottom: -1rem;
                    border-top: none;
                    margin-right: 0px;
                    margin-top: 0px;
                }
                tr {
                    th {
                        // box-shadow: 4px 0 6px -4px rgba(0, 0, 0, 0.25);
                        border-bottom: 2px solid $chart-color;
                        border-top: none;
                        color: $chart-color;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        text-align: center;
                    }
                }
            }

            tbody {
                tr {
                    .empty-list {
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        padding: 40px;
                        margin-top: 8px;
                        // width: 69%;
                        // position: absolute;
                        img {
                            width: 350px;
                            height: 210px;
                        }
                        p {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            margin-top: 15px;
                            color: #696873;
                        }
                    }
                    td {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                        vertical-align: middle;
                        border-top: 8px solid #f6f6f6 !important;
                        text-align: center;
                        border-bottom: 0px;
                        .user-table-holder {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            .user-icon-table {
                                width: 30px;
                                height: 30px;
                                overflow: hidden;
                                border-radius: 50%;
                                display: flex;
                                color: #fff;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                        p {
                            color: $button-color;
                        }
                    }
                    .action {
                        cursor: pointer;
                        img {
                            display: inline-block;
                            vertical-align: middle;
                        }
                        button {
                            border: none;
                            background: none;
                            //    &:hover{
                            //        background: #DDDDE2;
                            //        width: 30px;
                            //        height: 30px;
                            //        border-radius: 50%;

                            //    }
                        }
                    }
                }
            }
        }
    }
    .table-staff {
        margin-top: -45px;
    }
    .showing-results {
        padding-top: 8px;
        position: absolute;
        .clear {
            display: flex;

            .select-list {
                ul {
                    margin-top: 7px;
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Roboto;
                        font-size: 12px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        padding: 5px 7px;
                        background-color: #ffffff;
                        margin-right: 5px;
                        border-radius: 50px;
                        margin-bottom: 10px;
                        margin-top: -2px;
                        img {
                            width: 8px;
                            cursor: pointer;
                            vertical-align: middle;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            span {
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: $button-color;
                cursor: pointer;
            }
        }
    }
    .user-pagination {
        display: flex;
        align-items: center;
        margin-top: 25px;
        img {
            vertical-align: middle;
            cursor: pointer;
        }
        div {
            display: flex;
            align-items: center;
            width: 110px;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            input {
                width: 30%;
                border-radius: 4px;
                border: 1px solid #9b9aa3;
                padding: 0.25rem 0.3rem;
                text-align: center;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.upload-modal {
    width: 520px;
    .upload {
        position: relative;
        .close-upload {
            position: absolute;
            right: 0px;
            top: 3px;
            cursor: pointer;
        }
        h1 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #484750;
        }
        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            text-align: center;
            padding: 10px;
            width: 320px;
            margin: 0 auto;
        }
        button {
            margin-top: 20px;
            width: 200px;
            height: 40px;
            color: #fff;
            background-color: $button-color;
            border: none;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            border-radius: 4px;
        }
    }
}

.filter-modal {
    width: 45%;
    .filter {
        position: relative;
        h1 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #484750;
            text-align: center;
        }
        .close-upload {
            position: absolute;
            right: -35px;
            top: 3px;
            cursor: pointer;
        }
        label {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            aside {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
        input {
            width: 92%;
            border: 1px solid $chart-color;
            &:focus {
                outline: none !important;
                border-color: $chart-color;
                box-shadow: 0 0 5px $chart-color;
            }
        }
        .form-control:disabled,
        .form-control[readonly] {
            background-color: #f7f7fa;
        }
        .css-b62m3t-container {
            margin-top: 0.5rem;
            .css-1u9des2-indicatorSeparator {
                display: none;
            }
            .css-13cymwt-control {
                border-color: $chart-color !important;
            }
            input:focus {
                box-shadow: none !important;
            }
            .css-t3ipsp-control {
                border: 1px solid $chart-color;
                border-radius: 4px;
            }
        }
        .filter-btn {
            margin-top: 25px;
            button {
                border: none;
                background-color: $button-color;
                width: 184px;
                height: 40px;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
                border-radius: 4px;
                &:nth-child(2) {
                    border: 1px solid #f8f8f8;
                    background-color: #ffffff;
                    color: #222222;
                    margin-left: 5px;
                }
            }
        }
    }
}

.update-modal {
    width: 29%;
    .modal-content {
        border-radius: 8px;
        border: none;
    }
    .modal-header {
        position: relative;
        padding: 0.7rem 0.7rem;
        text-align: center;
        display: block;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        h5 {
            color: #fff;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;

            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
                margin-top: 4px;
            }
        }
        .sticky-close {
            position: absolute;
            right: 19px;
            top: 20px;
            cursor: pointer;
        }
    }
    .modal-body {
        label {
            margin-top: 10px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
        }
        input {
            border-color: $chart-color;
        }
        .css-b62m3t-container {
            margin-top: 0.5rem;
            .css-1okebmr-indicatorSeparator {
                display: none;
            }
            .css-13cymwt-control {
                border-color: $chart-color !important;
            }
            input:focus {
                box-shadow: none !important;
            }
            .css-t3ipsp-control {
                border: 1px solid $chart-color;
                border-radius: 4px;
            }
        }
        small {
            color: red;
            font-size: 12px;
        }
    }
}

.multival {
    .css-1rhbuit-multiValue {
        width: 46%;
    }
}

.popover {
    .arrow {
        display: none;
    }
    .popover-add-view {
        padding: 20px 25px;
        li {
            list-style: none;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            margin-bottom: 5px;
            cursor: pointer;
            img {
                vertical-align: middle;
            }
        }
    }
}
.css-3iigni-container {
    margin-top: 8px !important;
}

.add-form {
    width: 90%;
    margin-left: 20px;
    background-color: #fff;
    .student-header {
        display: flex;
        justify-content: space-between;
        width: 58%;
        align-items: center;
        img {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        p {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #484750;
        }
    }

    .form-inputs {
        margin-top: 20px;
        p {
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #9b9aa3;
        }
        span {
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: red;
        }
        .css-b62m3t-container {
            // width: %;
            margin-top: 0.5rem;
            .css-1u9des2-indicatorSeparator {
                display: none;
            }
            .css-13cymwt-control {
                border-color: $chart-color !important;
            }
            input:focus {
                box-shadow: none !important;
            }
            .css-t3ipsp-control {
                border: 1px solid $chart-color;
                border-radius: 4px;
            }
        }
        label {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 100%;
            aside {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
        input {
            // width: 92%;
            border: 1px solid $chart-color;
            text-transform: uppercase;
            &:focus {
                outline: none !important;
                border-color: $chart-color;
                box-shadow: none;
            }
        }
        .form-control:disabled,
        .form-control[readonly] {
            background-color: #f7f7fa;
        }
    }
    .add-student-form {
        padding: 25px 45px;
    }
    .details-row {
        border-top: 10px solid#f7f7fa;
        background: #fff;
        padding-left: 45px;
        padding-right: 30px;
        padding-top: 25px;
        padding-bottom: 25px;
        position: relative;
        // padding: 25px 45px;
        p {
            position: absolute;
            bottom: 25px;
            color: red;
            font-size: 14px;
            font-family: Roboto;
        }
        .add-row {
            font-style: normal;
            border: none;
            background: transparent;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $button-color;
        }
        .delete-row {
            height: 40px;
            border: none;
            box-shadow: none;
            margin-top: 35px;
            position: absolute;
            right: 0px;
            background: none;
            width: 35px;
            img {
                width: 20px;
                height: 20px;
            }
        }
        label {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 100%;
            aside {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
        input {
            // width: 92%;
            border: 1px solid $chart-color;
            text-transform: uppercase;
            &:focus {
                outline: none !important;
                border-color: $chart-color;
                box-shadow: none;
            }
        }
        .form-control:disabled,
        .form-control[readonly] {
            background-color: #f7f7fa;
        }
    }
    .save-btn {
        border-top: 10px solid#f7f7fa;
        background-color: #f7f7fa;
        button {
            border: none;
            background-color: $button-color;
            width: 136px;
            height: 32px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
            border-radius: 2px;
            &:nth-child(2) {
                border: 1px solid #f8f8f8;
                background: none;
                color: #222222;
                margin-left: 5px;
            }
        }
    }
    .add-staff-form {
        padding: 25px 45px;
        .toggle-box {
            width: 321px;
            // height: 37px;
            background: #fff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            margin-bottom: 10px;
            padding: 7px;
            p {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 14px;
                color: #696873;
            }
            img {
                width: 8px;
                height: 8px;
            }
        }
        .payment-history {
            h1 {
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                color: #484750;
            }
        }
    }
}

.view-form {
    background-color: #fff;
    .student-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        p {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #484750;
        }
        h3 {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $button-color;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .form-inputs {
        margin-top: 20px;

        p {
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #9b9aa3;
        }
        label {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 100%;
            aside {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
        input {
            border: 1px solid $chart-color;
            &:focus {
                outline: none !important;
                border-color: $chart-color;
                box-shadow: 0 0 5px $chart-color;
            }
        }
        .form-control:disabled,
        .form-control[readonly] {
            background-color: #f7f7fa;
        }

        .css-b62m3t-container {
            // width: %;
            margin-top: 0.5rem;
            .css-1u9des2-indicatorSeparator {
                display: none;
            }
            .css-13cymwt-control {
                border: 1px solid $chart-color !important;
            }
            input:focus {
                box-shadow: none !important;
            }
            .css-t3ipsp-control {
                border: 1px solid $chart-color;
                border-radius: 4px;
            }
        }
        .save-btn {
            margin-top: 15px;
            button {
                border: none;
                background-color: $button-color;
                width: 136px;
                height: 32px;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
                border-radius: 2px;
                &:nth-child(2) {
                    border: 1px solid #f8f8f8;
                    background-color: #ffffff;
                    color: #222222;
                    margin-left: 5px;
                }
            }
        }
    }
    .form-group {
        .file-upload {
            position: absolute;
            left: 0px;
            /* right: 106px; */
            height: 30px;
            width: 15vw;
            opacity: 0;
            top: -3px;
            cursor: pointer;
        }
    }
    .payment-history {
        h1 {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #484750;
        }
    }
    .add-student-form {
        padding: 25px 45px;
    }
    .add-staff-form {
        padding: 25px 45px;
    }
}

@media (min-width: 1400px) {
    .users-module {
        .table-admin {
            margin-top: -46px !important;
        }
    }
}
