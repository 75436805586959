@import "../../App.scss";

input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
}

input[type="file"] {
    cursor: pointer;
}

/* IE11 (if it doesn't work, try maybe also on the parent/wrapper) */

.account-module {
    font-family: Roboto;
    font-style: normal;
    width: 90%;
    margin-left: 5px;
    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        color: #484750;
        font-family: Roboto;
    }
    .users-button {
        button {
            margin-top: 3px;
            background: #f7f7fa;
            border: 1px solid #dddde2;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            // border: none;
            padding: 7px;
            width: 120px;
            border-radius: 4px;
            margin-left: 5px;
            &:nth-child(1) {
                margin-left: 0px;
            }
        }
        .active-btn {
            background: $dark-background;
            border: none;
            color: #fff;
            padding: 7px;
            width: 120px;
            font-weight: bold;
        }
    }
    .profile-holder {
        background-color: #fff;
        margin-top: 10px;
        padding: 25px 50px;
        width: 86%;
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #9b9aa3;
        }
        label {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 100%;
        }
        input {
            width: 53%;
            border: 1px solid $chart-color;
            text-transform: uppercase;
            &:focus {
                outline: none !important;
                border-color: $chart-color;
                box-shadow: none;
            }
        }
        .form-control:disabled,
        .form-control[readonly] {
            background-color: #f7f7fa;
        }
        .program-image {
            width: 139px;
            height: 78px;
            background-color: #f7f7fa;
            margin-top: 10px;
            margin-right: 15px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        span {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $button-color;
            margin-bottom: 10px;

            input {
                cursor: pointer;
            }
            img {
                width: 20px;
                height: 20px;
                vertical-align: middle;
            }
            .file-upload {
                position: absolute;
                left: 0px;
                height: 30px;
                width: 100%;
                opacity: 0;
                cursor: pointer;
                top: -2px;
            }
        }
        .save-btn {
            margin-top: 10px !important;
            button {
                border: none;
                background-color: $button-color;
                width: 136px;
                height: 32px;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
                border-radius: 2px;
                &:nth-child(2) {
                    border: 1px solid #f8f8f8;
                    background: none;
                    color: #222222;
                    margin-left: 5px;
                }
            }
        }
    }
    .school-list {
        position: relative;
        .users-header {
            background: #fff;
            padding: 25px 16px;
            margin-top: 8px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            padding-bottom: 56px;
            min-height: 131px;

            .add-btn {
                border: none;
                border-radius: 4px;
                background: $button-color;
                color: #fff;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                width: 200px;
                height: 40px;
                img {
                    vertical-align: middle;
                    width: 16px;
                    height: 16px;
                    margin-right: 15px;
                }
            }

            p {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
            h2 {
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: $button-color;
            }
        }
        .table-admin {
            // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            background: #fff;
            margin-top: -60px;
            .table {
                margin-bottom: 0px;
                thead {
                    hr {
                        margin-bottom: -1rem;
                        border-top: none;
                        margin-right: 0px;
                        margin-top: 0px;
                    }
                    tr {
                        th {
                            // box-shadow: 4px 0 6px -4px rgba(0, 0, 0, 0.25);
                            border-bottom: 2px solid $chart-color;
                            border-top: none;
                            color: $chart-color;
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            text-align: center;
                        }
                    }
                }

                tbody {
                    tr {
                        .empty-list {
                            background: #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            padding: 40px;
                            margin-top: 8px;
                            width: 100%;
                            // position: absolute;
                            img {
                                width: 350px;
                                height: 210px;
                            }
                            p {
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 18px;
                                margin-top: 15px;
                                color: #696873;
                            }
                        }
                        td {
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            color: #696873;
                            vertical-align: middle;
                            border-top: 8px solid #f6f6f6 !important;
                            padding: 0.95rem;
                            text-align: center;
                            .user-table-holder {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .user-icon-table {
                                    width: 30px;
                                    height: 30px;
                                    overflow: hidden;
                                    border-radius: 50%;
                                    display: flex;
                                    color: #fff;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                            p {
                                color: $button-color;
                            }
                        }
                        .action {
                            cursor: pointer;
                            img {
                                display: inline-block;
                                vertical-align: middle;
                            }
                            button {
                                border: none;
                                background: none;
                            }
                        }
                    }
                }
            }
        }

        .showing-results {
            padding-top: 8px;
            position: absolute;

            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                span {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $button-color;
                    cursor: pointer;
                }
            }
        }
        .user-pagination {
            display: flex;
            align-items: center;
            margin-top: 25px;
            img {
                vertical-align: middle;
                cursor: pointer;
            }
            div {
                display: flex;
                align-items: center;
                width: 110px;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                input {
                    width: 30%;
                    border-radius: 4px;
                    border: 1px solid #9b9aa3;
                    padding: 0.25rem 0.3rem;
                    text-align: center;
                }
            }
        }
    }
    .add-center {
        padding: 26px 45px;
        background-color: #fff;
        font-family: Roboto;
        font-style: normal;
        width: 99.5%;
        .table-admin .table thead th {
            background-color: #fff;
            // color: #fff;/
            padding: 13px 20px;
        }
        .table-admin .table tbody tr td {
            border-top: 15px solid #f9f9ff !important;
            padding: 13px 20px;
            background-color: #fff;
            vertical-align: middle;
        }
        small {
            color: red;
            font-size: 14px;
            padding: 4px 0px;
        }
        h2 {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #484750;
            img {
                vertical-align: middle;
                cursor: pointer;
            }
        }

        p {
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #9b9aa3;
            margin-top: 12px;
        }
        label {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
        }
        select,
        input {
            // width: 86%;
            border-color: $button-color;
            margin-bottom: 5px;
        }
        textarea {
            width: 86%;
            border-color: $button-color;
            margin-bottom: 5px;
        }

        .image-upload {
            .program-image {
                width: 139px;
                height: 78px;
                background-color: #f7f7fa;
                margin-top: 10px;
                margin-right: 15px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            span {
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: $button-color;
                margin-bottom: 10px;

                input {
                    cursor: pointer;
                }
                img {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                }
                .file-upload {
                    position: absolute;
                    left: 0px;
                    height: 30px;
                    width: 100%;
                    opacity: 0;
                    cursor: pointer;
                    top: -2px;
                }
            }
        }
        .css-b62m3t-container {
            .css-1okebmr-indicatorSeparator {
                display: none;
            }
        }
        .css-b62m3t-container .css-13cymwt-control {
            border: 1px solid $button-color;
            border-radius: 4px;
            .css-1u9des2-indicatorSeparator {
                display: none;
            }
        }
        .css-t3ipsp-control {
            .css-1u9des2-indicatorSeparator {
                display: none;
            }
        }
    }
}
.no-spin::-webkit-outer-spin-button,
.no-spin::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
}
/* Firefox */
.no-spin {
    -moz-appearance: textfield;
}
.cursor-pointer {
    cursor: pointer;
}

@media (min-width: 1400px) {
    .users-module {
        .table-admin {
            margin-top: -45px;
        }
    }
}

@media (max-width: 767px) {
    .account-module {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 30px;
        .add-center {
            width: 96%;
            padding: 25px 25px;
            hr {
                margin-right: 5px;
            }
            textarea {
                width: 100%;
            }
            input {
                width: 100%;
            }
        }
        .table-admin {
            table tr th:first-child {
                z-index: 5 !important;
            }
            .table-admin {
                width: 100%;
                min-height: 345px;
                overflow: scroll;
            }
            table th,
            table td {
                white-space: nowrap;
                padding: 10px 20px;
            }
            table tr th:first-child,
            table td:first-child {
                position: sticky;
                width: 100px;
                min-width: 130px;
                max-width: 130px;
                left: -1px;
                z-index: 5;
                background: #fff;
                overflow: hidden;
                // display: -webkit-box;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;
            }

            table tr th {
                position: sticky;
                top: 0;
                z-index: 4;
                background: #fff;
            }
        }
        .users-button {
            display: flex;
            overflow: auto;
            button {
                padding: 7px 45px;
                width: unset;
            }
            .active-btn {
                padding: 7px 45px;
                width: unset;
            }
        }
        .profile-holder {
            width: 96%;
            padding: 25px 25px;
            input {
                width: 100%;
            }
            hr {
                margin-right: 5px;
            }
        }
    }
}
