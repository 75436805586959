@import "../../App.scss";

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-decoration: none !important;
}

.side-bar {
    width: 290px;
    background-color: $sidebar-color;
    position: fixed;
    bottom: 0;
    top: 0;
    z-index: 8;
    box-shadow: 0 0 15px 0 rgba(82, 63, 105, 0.08);
    .menu-close {
        height: 10px;
        position: absolute;
        display: none;
        .close {
            font-size: 30px;
            position: relative;
            top: -55px;
            left: 265px;
            border: none;
            background: transparent;
            color: #fff;
            @media (max-width: 374px) {
                //left: 244px;
            }
        }
        @media (max-width: 1200px) {
            display: unset;
        }
    }
    .logo-dashboard {
        text-align: center;
        padding: 6px 0;
        // border-bottom: 1px solid #9896961a;
        height: 75px;
        padding-bottom: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: $sidebar-color 0% 0% no-repeat padding-box;
        .logo {
            background-color: #fff;
            border-radius: 4px;
            padding: 3px;
        }
    }
    .profile-overflow {
        max-height: calc(100vh - 6rem);
        overflow-y: auto;
        .profile-side-bar {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 24px;
            height: 102px;
            .profile-side-bar-avatar {
                width: 69px;
                overflow: hidden;
                border-radius: 6px;
                // img {
                //     width: 65px;
                //     height: 65px;
                // }
            }
            .profile-details {
                flex: 2;
                padding-left: 15px;
                h3 {
                    font-family: "Roboto";
                    color: #fff;
                    font-weight: bold;
                    font-size: 20px;
                    text-transform: capitalize;
                }
                h4 {
                    color: #fff;
                    font-family: "Roboto";
                    font-size: 12px;
                    margin-top: 8px;
                }
            }
        }
        .profile-score {
            padding-left: 15px;
            // margin-top: 27px;
            // padding-bottom: 33px;
            padding-right: 15px;
            display: flex;
            // padding: 14px 21px;
            margin-bottom: 20px;
            height: 87px;
            .engagement-layout-holder {
                background-color: rgba(96, 96, 96, 0.04);
                flex: 2;
                padding-left: 10px;
                padding-top: 10px;
                border: 4px solid #fff;
                border-radius: 10px;
                h4 {
                    font-size: 12px;
                    font-family: "Roboto";
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    color: #fff;
                }
                h5 {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    padding-top: 15px;
                    color: #fff;
                    width: 15px;
                    height: 15px;
                }
            }
            .engagement-logo {
                // display: flex;
                padding-left: 80px;
                margin-top: 0px;

                img {
                    width: 23px;
                }
            }
        }
        .profile-score1 {
            padding-left: 24px;
            // margin-top: 27px;
            background-color: #6060600a;
            // padding-bottom: 33px;
            padding-right: 24px;
            display: flex;
            padding: 14px 21px;
            margin-bottom: 20px;
            height: 87px;
            .engagement-layout-holder {
                flex: 2;
                h4 {
                    font-size: 15px;
                    font-family: "Roboto";
                    color: #fff;
                    font-weight: 600;
                }
                h5 {
                    font-size: 30px;
                    font-family: "Roboto";
                    color: #fff;
                }
            }
            .engagement-logo {
                display: flex;
                padding-top: 10px;
                img {
                    width: 23px;
                }
            }
        }
        .dashboard-links {
            padding-left: 24px;
            margin-right: 24px;
            ul {
                margin: 0;
                padding: 0;
                width: 100%;
                a {
                    text-decoration: none;
                    color: #fff;
                }
                li {
                    list-style-type: none;
                    padding: 10px 0;
                    // background: #fff;
                    padding-left: 22px;
                    border-radius: 4px;
                    color: #c0beff;
                    cursor: pointer;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    letter-spacing: 0px;
                    text-align: left;
                    img {
                        vertical-align: middle;
                        margin-right: 15px;
                        width: 25px;
                    }
                }
            }
        }
    }
}

.intro-video {
    width: 50%;
    .modal-header {
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        color: white;
        font-size: 20px;
        font-family: Roboto;
    }
    .main-modal-header {
        padding: 15px;
        h3 {
            font-size: 22px;
        }
    }
    .sticky-close {
        position: absolute;
        right: 15px;
        top: 18px;
        cursor: pointer;
        img {
            width: 18px;
        }
    }
}
.profile-overflow {
    scrollbar-width: thin;
    & ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $sidebar-color;
        color: red;
    }
    &::-webkit-scrollbar {
        width: 0px;
        background-color: $sidebar-color;
        color: red;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        max-height: 10px;
        background-color: $sidebar-color;
    }
}

.layout-container {
    padding-top: 90px;
    padding-left: 321px;
    padding-bottom: 10px;
}

.dashboard-active {
    // background: #edf3f3 !important;
    background: #4f4e87 !important;
    color: #fff !important;
}

.search1 {
    position: relative;
    width: 53%;
    display: inline-block;
    .suggestion-container {
        position: absolute;
        z-index: 8;
        width: 100%;
        // left:2px;
        background: #fff;
        box-shadow: 0px 5px 7px 2px #0000001f;
        text-align: left;
        top: 2px;
        // border-bottom-left-radius: 5px;
        border-radius: 4px;
        // padding-bottom: 14px;
        i {
            margin-right: 7px;
        }
        hr {
            margin-top: -10px;
        }
        ul {
            &:hover li:hover {
                background: #ddd;
            }
            li {
                display: flex;
                align-items: center;
                cursor: pointer;
                width: 100%;
                padding: 7px 0 6px 20px;
                border-bottom: 1px solid #dddde2;
                margin: 0;
                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #9b9aa3;
                }
                span {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: #696873;
                }
                // padding-left: 11px;
            }
        }
    }
}

.dashboard-header {
    position: fixed;
    left: 0;
    width: 100%;
    padding-left: 326px;
    display: flex;
    flex-wrap: wrap;
    font-family: "Roboto";
    background: #ffffff 0% 0% no-repeat padding-box;
    z-index: 6;
    padding-top: 16px;
    padding-bottom: 17px;
    box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.08);
    padding-right: 20px;
    align-items: center;
    justify-content: space-between;
    .searchIcon {
        display: none;
    }
    .logo1 {
        margin-right: 1531px;
        margin-left: -230px;
        margin-top: -55px;
        margin-bottom: -12px;
        cursor: pointer;
        background-color: #fff;
        padding: 4px;
        border-radius: 4px;
        img {
            width: 70px;
            height: 60px;
        }
        // cursor: pointer;
    }
    .Colapselink {
        display: none !important;
    }
    .has-search {
        width: 53%;
        margin-top: -37px;
        // margin-left: -100px;
        span {
            margin-top: 4px;
            width: 24px;
            margin-left: 5px;
        }
    }

    .form-control {
        padding-left: 2.375rem;
        // height: 27px;
    }
    .form-control-feedback {
        position: absolute;
        z-index: 5;
        display: block;
        width: 2.375rem;
        height: 2.375rem;
        line-height: 2.375rem;
        text-align: center;
        pointer-events: none;
        color: #aaa;
    }
    .input-fixed {
        position: sticky;
        position: -webkit-sticky;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 66px;
        // background: #fff;
        // left: 19;
        width: 90%;
        padding: 0px 6px;
        z-index: 5;
        // border-bottom: #eee solid 1px;
        // border-top: #eee solid 1px;
        .left {
            margin-left: -7px;
            width: 100%;

            small {
                color: #a2a2a2;
            }
        }

        button {
            width: 45px;
            height: 40px;
            margin-top: 10px;
            // font-size: 16px;
            margin-right: -45px;
            background-color: #fff;
            border-radius: 5px;
            display: flex;
            justify-content: center;
        }
    }
}

.Colapselink button.rounded-circle {
    font-size: 22px;
    padding: 0;
    outline: none;
    color: white;
    margin-bottom: 10px;
    &:focus {
        box-shadow: none;
    }
}

.dashboard-header-heading {
    float: left;
    width: 70%;
    h3 {
        font-size: 19px;
        margin-bottom: 0;
        padding-top: 8px;
    }
    input {
        margin-top: 20px;
        width: 100%;
    }
    display: flex;
    justify-content: space-between;

    .mobile-logo {
        .logo {
            background-color: #fff;
            border-radius: 4px;
            padding: 3px;
        }
        img {
            width: 45px;
            height: 38px;
            padding: 3px;
            display: block;
        }
    }
    .searchIcon {
        // width: 41px;
        // height: 42px;
        // border: 1px solid white;
        border-radius: 50px;
        margin-right: 38px;
        margin-top: 3px;
    }
}
.bell {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
    img {
        // width: 100px;
        text-align: center;
        vertical-align: middle;
    }
    .badge {
        background: red;
        color: white;
        width: auto;
        height: auto;
        min-width: 15px;
        min-height: 15px;
        margin: 0;
        border-radius: 50%;
        position: absolute;
        top: -7px;
        right: -6px;
        padding: 3px;
        font-size: 11px;
    }
}
.dashboard-profile-holder {
    float: right;
    height: 40px !important;
    padding-top: 2px;
}
.drop-inst {
    position: absolute;
    background: #ffffff;
    width: 327px;
    right: 27px;
    opacity: 0;
    visibility: hidden;
    transform: translate(10px, 15px);
    transition: all 0.3s ease;
    top: 55px;
    z-index: 9999;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(82, 63, 105, 0.15);
    // border: 1px solid #0000001c;
    z-index: 9999;
    @media (max-width: 375px) {
        width: 300px !important;
    }
    .fa {
        color: $chart-color;
        margin-right: 12px;
    }
    ul {
        li {
            display: flex;
            align-items: center;
            padding: 18px 16px;
            cursor: pointer;
            transition: all 0.2s ease;
            &:hover {
                border-radius: 4px;
                background: #f3f3f3;
            }
            .list-text {
                h4 {
                    color: #595d6e;
                    font-size: 14px;
                    margin-bottom: 2px;
                }
                p {
                    color: #74788d;
                    font-size: 12px;
                }
            }
        }
    }
    .logout {
        font-size: 13px;
        padding: 17px;
        background: rgba(93, 120, 255, 0.1);
        /* width: 24px; */
        color: #5d78ff;
        cursor: pointer;
    }
}
.drop-banner {
    display: flex;
    padding: 12px 15px;
    background: $sidebar-color;
    // border-top-right-radius: 5px;
    align-items: center;
    .drop-profile-name {
        color: #fff;
        margin-left: 10px;
        text-transform: capitalize;
    }
    .profile-banner-drop {
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 24px;
        font-weight: bold;
        font-family: "Roboto";
        color: #fff;
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
    }
}

.drop-profile-hold {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border: 2px solid #ddd;
    border-radius: 25px;
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    // background-color: #7666ea;
}
.initial-circle-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 700;
    font-family: Roboto;
    color: #fff;
}

.dropmain-holder {
    // opacity: 1;
    &:hover {
        .drop-inst {
            opacity: 1;
            visibility: visible;
            transform: translate(11px, 10px);
            z-index: 9999;
            border-radius: 4px;
        }
    }
}

.profile-loader {
    padding: 20px;
}

button {
    outline: none;
    &:focus {
        outline: none;
    }
}

.secondary-button {
    color: #6457e8;
    padding: 0px 36px;
    border: 1px solid #6457e8;
    border-radius: 5px;
    margin-right: 13px;
    height: 58px;
    cursor: pointer;
}

// Radio custom

.loading-center {
    text-align: center;
    width: 100%;
    margin-top: 47px;
}

.initial-circle {
    color: #fff;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    // background: $darker-background;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    font-family: "Roboto";
}

.modal {
    background: rgba(0, 0, 0, 0.11) !important;
    backdrop-filter: blur(8px);
}

.connection-status {
    width: 100%;
    color: #fff;
    background: red;
    position: fixed;
    bottom: 0;
    z-index: 777;
    text-align: center;
    padding: 8px;
    font-size: 20px;
    font-weight: 600;
    font-family: "Roboto";
}

/*some styles for this example*/

.mobile-coming {
    display: none;
    @media screen and (max-width: 1200px) {
        display: block;
    }
    .mobile-inner-hide {
        background-color: #766ceb;
        position: fixed;
        top: 0;
        left: 0px;
        width: 100%;
        height: 90%;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        // display: none;
        img {
            width: 228px;
            margin-bottom: 19px;
        }
        h4 {
            color: #fff;
            font-family: "Roboto";
            font-size: 20px;
            text-align: center;
        }
        p {
            color: #fff;
            font-family: "Roboto", sans-serif;
            font-size: 15px;
            margin-top: 8px;
        }
    }
}
.under-maintainence {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #524b98b3;
    backdrop-filter: blur(10px);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column;
    display: none;
    h3 {
        font-size: 48px;
        margin-bottom: 17px;
        font-family: "Roboto";
        font-weight: 600;
    }
    p {
        color: #fff4f4;
        font-size: 19px;
        font-family: "Roboto";
        font-weight: 300;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-position: 98% center;
    background-repeat: no-repeat;
    outline: none;
    border: 1px solid #ddd;
    border-radius: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    padding: 8px 7px;
    // height: 50px;
    width: 100%;
}
select::-ms-expand {
    display: none;
}

.hide-background {
    overflow: hidden;
}

#fc_frame,
#fc_frame.fc-widget-normal {
    z-index: 999999999 !important;
}

// .BodyCloseNav .layout-container {
//     padding-left: 15px;
// }

@media (max-width: 767px) {
    .layout-container {
        padding-left: 0px;
    }

    .menu-active {
        border-radius: 16px;
        padding: 3px;
        background-color: #e8def8 !important;
        img {
            width: 24px !important;
            height: 24px !important;
        }
    }

    .mobilefooter-links {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background: #fff;
        z-index: 8;
        padding: 0 10px;
        // padding-right: 0px;
        box-shadow: rgba(51, 51, 51, 0.25) 0 0 14px;
        ul {
            width: 100%;
            display: block;
            margin: 0px;
            padding-inline-start: 0px;
            li {
                width: 25%;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                font-size: 12px;
                color: #49454f;
                padding: 7px 0;
                position: relative;
                small {
                    display: block;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: center;
                    margin-top: 5px;
                }
                img {
                    width: 30px;
                    margin-bottom: 0;
                    height: 30px;
                }
            }
        }
    }

    #fc_frame {
        display: none;
    }
    #fc_frame.fc-widget-normal {
        bottom: 95px !important;
        right: 6px !important;
    }

    .intro-video {
        width: unset;
    }
    .layout-container {
        padding-bottom: 50px !important;
    }

    .side-bar {
        width: 300px;
        z-index: 10 !important;
    }
    .dashboard-container {
    }
    .dropdown-card {
        top: 18px;
    }
    .profile-overflow {
        height: auto !important;
    }
    .dashboard-header {
        position: fixed;
        // top: 0px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        background: #ffffff;
        padding-bottom: 8px;
        z-index: 7;
        // overflow: hidden;
        .Colapselink {
            display: block !important;
            margin-left: -7px;
            button.rounded-circle {
                width: 45px;
            }
        }
        .dashboard-profile-holder {
            padding-top: 0px;
            margin-left: 15px;
        }
        .logo1 {
            display: none;
        }
        .searchIcon {
            display: block;
            margin-top: 5px !important;
        }
        .mobile-bell {
            margin-left: -39px;
            border: 1px solid #ffffff;
            width: 35px;
            height: 35px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
            margin-top: -2px;
            position: relative;
            img {
                text-align: center;
                vertical-align: middle;
            }
            .badge {
                background: red;
                color: white;
                width: auto;
                height: auto;
                margin: 0;
                border-radius: 50%;
                position: absolute;

                top: -2px;
                right: 3px;
                padding: 3px;
                font-size: 13px;
            }
        }
        .none {
            display: none;
        }
        .has-search {
            // display: none;
            margin-top: 10px;
            span {
                margin-top: 4px;
                width: 11px;
                margin-left: 0px;
            }
            // .form-control {
            //   padding-left: 2.375rem;
            //   height: 26px;
            // }
            .form-control-feedback {
                position: absolute;
                z-index: 5;
                display: block;
                width: 2.375rem;
                // height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: #aaa;
            }
        }
    }
    .search1 {
        width: 100%;
        .suggestion-container {
            position: absolute;
            z-index: 8;
            width: 100%;
            background: #fff;
            box-shadow: 0 5px 7px 2px rgba(0, 0, 0, 0.12);
            text-align: left;
            top: 0px;
            // border-bottom-left-radius: 5px;
            // border-bottom-right-radius: 5px;
            border-radius: 4px;
            margin-left: 0px !important;
            i {
                margin-right: 7px;
            }
            ul {
                &:hover li:hover {
                    background: #ddd;
                }
                li {
                    cursor: pointer;
                    // width: 100%;
                    padding: 14px 0;
                    // border-bottom: 1px solid #00000026;
                    margin: 0;
                    padding-left: 11px;
                }
            }
        }
    }

    .dashboard-show-we-chat > #fc_frame {
        display: block;
    }
}

@media (max-width: 1024px) {
    .TopRight .Colapselink {
        display: block;
    }

    .dashboard-header,
    .layout-container {
        padding-left: 15px;
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    // .dashboard-header .Colapselink {
    //     display: block !important;
    // }
    .side-bar {
        opacity: 1 !important;
        visibility: visible !important;
        .menu-close {
            display: none !important;
        }
        &::before {
            background-color: transparent !important;
            width: 0px !important;
        }
    }
}

@media (max-width: 1200px) {
    .BodyCloseNav .side-bar {
        opacity: 0;
        visibility: hidden;
    }

    .side-bar {
        width: 295px;
        left: 0;
        transition: all 0.3s ease-in-out 0s;
        overflow-y: auto;
        .profile-score {
            margin-bottom: 0;
        }
        &:before {
            background: rgba(0, 0, 0, 0.81);
            content: "";
            position: fixed;
            height: 100%;
            width: calc(100% - 293px);
            display: inline-block;
            top: 0;
            right: 0;
        }
        .dashboard-links {
            padding: 0 15px;
            ul {
                li {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 15px;
                }
            }
        }
        .logo-dashboard {
            img {
                height: auto;
                width: auto;
                max-width: 73px;
            }
        }
    }
    .dropmain-holder {
        margin-top: -8px;
    }
}

@media (min-width: 414px) and (max-width: 736px) {
    .dashboard-container .mobilefooter-links ul li small {
        display: block;
        font-size: 12px;
    }
}

@media (max-width: 767px) {
    .dashboard-header-heading {
        width: 50% !important;
        .searchIcon {
            display: block !important;
        }
    }
}

@media (max-width: 767px) and (orientation: landscape) {
    .dashboard-header-heading {
        .searchIcon {
            display: block !important;
        }
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    // .profile-overflow {
    //   height: auto !important;
    // }
    .bell {
        margin-left: 15%;
    }
    .layout-container {
        padding-left: 15px !important;
    }
    .dashboard-header {
        .Colapselink {
            display: block !important;
            button.rounded-circle {
                margin-bottom: -5px;
            }
        }
    }
    .dashboard-header .has-search {
        margin-top: -40px;
        margin-left: 46px;
    }
    .searchPage {
        margin-left: 46px !important;
    }
    // .suggestion-searchPage {
    //   margin-left: 46px !important;
    // }
    .side-bar {
        display: block !important;
    }
}
@media (min-width: 1441px) and (max-width: 1680px) {
    // .suggestion-container {
    //   width: 45%;
    // }
}
@media (min-width: 1080px) and (max-width: 1920px) {
    .dashboard-header {
        .logo1 {
            margin-right: 1531px;
            margin-left: -230px;
            margin-top: -55px;
            margin-bottom: -12px;
            cursor: pointer;
            img {
                width: 70px;
                height: 60px;
            }
        }
    }
}

@media only screen and (min-width: 1366px) {
    .searchPage {
        width: 46%;
        // margin-left: -100px !important;
    }
}
@media only screen and (min-width: 1400px) {
    // .profile-overflow {
    //   height: auto !important;
    // }
}
@media only screen and (min-width: 1920px) {
    .side-bar {
        // width: 420px !important;
        .profile-score {
            .engagement-logo {
                padding-left: 129px;
            }
        }
    }
}

.popover-inner {
    width: 500px !important;
    max-width: 500px !important;
}
.popover-header {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $sidebar-color;
    button {
        border: none;
        background-color: #fff;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: $button-color;
    }
}
#popover-contained {
    // position: absolute;
    width: 360px;
    max-width: 500px !important;
    left: -270px;
    top: 100%;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 18px;
    margin-left: -7px;

    border: none;

    .popover-body {
        .notification-row {
            display: flex;
            align-items: center;
            // max-height: 132px;
            height: 81px;
            cursor: pointer;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #f7f7fa;
            .user-image {
                // flex: 1;
                width: 18%;
                padding-left: 13px;
                color: #fff;
                div {
                    border-radius: 50px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    img {
                        border-radius: 50%;
                    }
                }
            }
            h1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
        padding: 0px;
    }
    .arrow {
        margin: 0 16.3rem;
        display: none !important;
    }
}

@media (max-width: 767px) {
    .popover-header {
        button {
            display: block;
        }
    }
    #popover-contained {
        width: 355px;
        max-width: 500px !important;
        left: 3px !important ;
        top: 70%;
        margin-top: 13px !important;
        margin-left: -20px;
    }
    .popover {
        width: 347px;
        max-width: 500px !important;
        left: -250px;
        top: 100%;
        margin-top: 20px;
        background: #fff 0 0 no-repeat padding-box;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        .popover-body {
            .notification {
                .user-image {
                    padding-left: 15px;
                }

                .content {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .arrow {
            margin: 1px 16.1rem;
        }
    }
}

@media (min-width: 2732) and (max-width: 2048) and (orientation: landscape) {
    .BodyCloseNav .side-bar {
        left: 0px !important;
        // opacity: 1 !important;
    }
}
