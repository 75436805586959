.notification-holder {
    margin-top: 30px;
    margin-left: 20px;
    width: 66%;

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #222222;
        display: flex;
        align-items: center;
        img {
            cursor: pointer;
        }
    }
    .notification-list {
        list-style: none;
        background-color: #fff;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        margin-top: 5px;
        li {
            display: flex;
            align-items: center;
            // max-height: 132px;
            height: 81px;
            cursor: pointer;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #f7f7fa;
            padding-left: 20px;
            .icon-image {
                width: 7%;
            }
            .user-image {
                // flex: 1;
                width: 10%;
                // padding-left: 13px;
                color: #fff;
                div {
                    border-radius: 50px;
                    width: 40px;
                    height: 40px;
                    // border: 1px solid #6457e8;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    img {
                        border-radius: 50%;
                    }
                }
            }
            h2 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
    }
}

@media (max-width: 767px) {
    .notification-holder {
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
        margin-bottom: 30px;
        .notification-list {
            li {
                padding-left: 10px;
                .icon-image {
                    width: 10%;
                }
                .user-image {
                    width: 18%;
                    div {
                    }
                }
            }
        }
    }
}
