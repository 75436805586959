@import "../../App.scss";

.manage-content-holder {
    font-style: normal;
    font-family: Roboto;
    width: 90%;
    margin-left: 5px;
    h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #484750;
        font-family: Roboto;
    }
    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #222222;
        img {
            vertical-align: middle;
            cursor: pointer;
        }
    }

    .quizButton {
        background: $button-color;
        border: none;
        width: 200px;
        height: 40px;
        border-radius: 4px;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 18px;
    }
    .content-tabs {
        margin-top: 5px;
        font-family: Roboto;
        h4 {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
        }
        button {
            background: #f7f7fa;
            border: 1px solid #dddde2;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            // border: none;
            padding: 7px;
            width: 120px;
            border-radius: 4px;
        }
        .active-btn {
            background: $dark-background;
            border: none;
            color: #fff;
            padding: 7px;
            width: 120px;
            font-weight: bold;
        }
    }
    .content-header {
        background: #fff;
        padding: 18px;
        margin-top: 8px;
        padding-bottom: 5px;
        position: relative;
        border-bottom: 2px solid $chart-color;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

        .has-search {
            position: relative;
            .form-control-feedback {
                position: absolute;
                z-index: 5;
                display: block;
                width: 2.375rem;
                height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: #aaa;
                margin-top: 3px;
                width: 24px;
                margin-left: 5px;
            }

            .form-control {
                padding-left: 1.9rem;
            }
            #search {
                background-color: #f7f7fa;
                border: none;
                &::-webkit-input-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &::-moz-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &:-ms-input-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &::placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
            }
        }
        .select {
            padding-left: 120px;
            padding-right: 52px;
        }
        .select-qes {
            position: relative;
            p {
                margin-top: 5px;
                position: absolute;
                left: -90px;
            }
        }
        .content-heading {
            .search-input {
                position: relative;
                // margin-top: 36px;
                align-items: flex-start;
                padding-left: 10px;
                // padding-right: 52px;
                h2 {
                    font-family: Lato;
                    font-size: 32px;
                    font-weight: normal;
                    color: $button-color;
                    width: 71px;
                    height: 71px;
                    background-color: #edf3f3;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .symbol {
                    position: absolute;
                    top: 12px;
                    left: 7px;
                }
                input {
                    padding-left: 25px;
                    padding-bottom: 5px;
                    font-size: 12px;
                    font-weight: normal;
                    width: 440px;
                    font-family: Roboto;
                    outline: none;
                    border: 1px solid $chart-color;
                }
                .css-b62m3t-container .css-1u9des2-indicatorSeparator {
                    display: none !important;
                }
                .css-13cymwt-control {
                    min-height: 40px;
                    border: 1px solid $chart-color;
                    border-radius: 4px;
                    &:focus {
                        border: 1px solid $chart-color;
                        border-radius: 4px;
                        box-shadow: none;
                    }
                }
            }
        }
        button {
            background: $button-color;
            border: none;
            min-width: 200px;
            padding: 0px 10px;
            height: 40px;
            border-radius: 4px;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 18px;
            .arrow-right,
            .long-arrow-right {
                transform: rotate(135deg);
            }
            .arrow-right,
            .arrow-left,
            .long-arrow-right,
            .long-arrow-left {
                display: block;
                width: 7px;
                height: 7px;
                border-top: 2px solid #fff;
                border-left: 2px solid #fff;
                margin-left: 24px;
                position: absolute;
                right: 24px;
            }
            img {
                width: 14px;
            }
        }
        .select-qes-table {
            margin-top: 0;
        }
        ul {
            list-style: none;
            margin-top: 20px;
            li {
                display: flex;
                // text-align: center;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: $chart-color;
                cursor: default;
                div:first-child {
                    width: 45%;
                    padding-left: 40px;
                    color: $chart-color;
                }
                div {
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                        width: 20%;
                        color: $chart-color;
                        text-align: center;
                    }
                }
            }
        }
    }

    .select-qes-table {
        li {
            div:first-child {
                padding-left: 0;
                width: 10%;
            }
        }
    }
    .program-list {
        list-style: none;
        margin-top: 8px;
        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            padding: 13px 22px;
            cursor: pointer;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            background-color: #fff;
            margin-bottom: 8px;
            position: relative;
            // &:hover{
            //   box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
            // }
            .program-overlay {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                z-index: 4;
                top: 0px;
                width: 85%;
                height: 56px;
            }

            h3 {
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: #696873;
                font-family: Lato;
            }
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                .react-switch-handle {
                    height: 12px !important;
                    width: 12px !important;
                    top: 2px !important;
                    left: 1px;
                }
            }
            .program-name {
                width: 45%;
                img {
                    display: inline-block;
                    vertical-align: middle;
                }
                p {
                    display: inline-block;
                    margin-left: 10px;
                }
            }
            div {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    width: 20%;
                }
                img {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }
            div:nth-child(6) {
                width: 20%;
                text-align: center;
            }
        }
    }
}

.add-subject {
    font-family: Roboto;
    font-style: normal;
    background-color: #ffffff;
    padding-left: 52px;
    padding-bottom: 52px;
    padding-top: 25px;
    width: 90%;
    margin-left: 20px;
    .upload-img {
        span {
            margin-bottom: 18px !important;
        }
    }
    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #484750;
        img {
            vertical-align: middle;
            cursor: pointer;
        }
    }
    p {
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #9b9aa3;
        margin-top: 12px;
    }
    label {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
        width: 52%;
        aside {
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #9b9aa3;
        }
    }
    input {
        width: 52%;
        border-color: $chart-color;
    }
    textarea {
        resize: none;
        width: 52%;
        border-color: $chart-color;
        height: 115px;
    }
    .custom-radio-button {
        label {
            // display: flex;
            align-items: center;
            // width: auto;
            // margin-right: 40px;
            input[type="radio"] {
                appearance: none;
                border: 1px solid #9b9aa3;
                width: 14px !important;
                height: 14px;
                border-radius: 50%;
                position: relative;
                margin-right: 9px;
            }
        }
    }
    .custom-radio-button input:checked {
        position: relative;
        border-color: $chart-color !important;
    }
    .custom-radio-button input:checked:after {
        top: 3px;
        left: 3px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $button-color;
        content: "";
        position: absolute;
    }

    select {
        border-color: $chart-color;
    }
    .duration {
        select {
            width: 12%;
            border-color: $chart-color;
        }
        input {
            width: 10%;
        }
    }

    .program-image {
        width: 139px;
        height: 78px;
        background-color: #f7f7fa;
        margin-top: 10px;
        margin-right: 15px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: $button-color;
        margin-bottom: 10px;
        input {
            cursor: pointer;
        }
        img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
        }
        .file-upload {
            position: absolute;
            left: 0px;
            height: 30px;
            width: 100%;
            opacity: 0;
            cursor: pointer;
            top: -7px;
        }
        .file-upload_question {
            position: absolute;
            left: 0;
            height: 30px;
            width: 100%;
            opacity: 0;
            cursor: pointer;
            top: 0;
        }
    }
    small {
        color: red;
        font-size: 12px;
    }
    .active-sem {
        background-color: $button-color;
        color: #fff !important;
        border-radius: 4px;
    }
    .css-b62m3t-container {
        .css-1okebmr-indicatorSeparator {
            display: none;
        }
    }
    .css-b62m3t-container .css-13cymwt-control {
        border: 1px solid $chart-color;
        border-radius: 4px;
        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }
    .css-b62m3t-container .css-t3ipsp-control {
        border: 1px solid $chart-color;
        border-radius: 4px;
        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }
}

//Modal
.add-subject-modal {
    width: 29%;
    .modal-content {
        border-radius: 4px;
        border: none;
    }
    .modal-header {
        position: relative;
        // padding: 0.5rem 0.5rem;
        text-align: center;
        display: block;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        h5 {
            color: #fff;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 22px;
        }
        .sticky-close {
            position: absolute;
            right: 13px;
            top: 22px;
            cursor: pointer;
        }
    }
    .modal-body {
        label {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 100%;
            aside {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
        input,
        select {
            border-color: $chart-color;
        }
        textarea {
            border-color: $chart-color;
        }
        .css-2b097c-container .css-1okebmr-indicatorSeparator {
            display: none !important;
        }
        .css-yk16xz-control {
            min-height: 40px;
            border: 1px solid $chart-color;
            border-radius: 4px;
            &:focus {
                border: 1px solid $chart-color;
                border-radius: 4px;
                box-shadow: none;
            }
        }
        .program-image {
            width: 139px;
            height: 78px;
            background-color: #f7f7fa;
            margin-top: 10px;
            margin-right: 15px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        small {
            font-size: 12px;
            color: red;
        }
        span {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $button-color;
            margin-bottom: 10px;
            img {
                width: 20px;
                height: 20px;
                vertical-align: middle;
            }
            .file-upload {
                position: absolute;
                left: 3px;
                height: 30px;
                width: 100%;
                opacity: 0;
                cursor: pointer;
                top: -3px;
            }
        }
    }
}

.animated-search {
    position: absolute;
    right: 30px;
    overflow: hidden;
    top: 25px;
    #express-form-typeahead {
        position: relative;
        border: none !important;
        border-bottom: 1px solid #dddde2 !important;
        box-sizing: border-box;
        outline: none;
        padding: 0 0 0 40px;
        transition: width 0.8s ease;
        z-index: 2;
        opacity: 0;
        top: -10px;
        cursor: pointer;
        &:focus {
            z-index: 0;
            opacity: 1;
        }
    }
    .symbol {
        position: absolute;
        top: 0;
        left: 0px;
        z-index: 1;
        img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
        }
    }
    #express-form-typeahead {
        border: none;
        cursor: pointer;
        height: 37px;
        margin: 3px 0;
        padding: 0 0 0 34px;
        position: relative;
        -webkit-transition:
            width 400ms ease,
            background 400ms ease;
        transition:
            width 400ms ease,
            background 400ms ease;
        width: 0;
        &:focus {
            background-color: #fff;
            cursor: text;
            outline: 0;
            width: 280px;
            left: -10px;
            margin: 3px 0;
            padding: 0 0 0 34px;
            transition: width 0.8s ease;
            z-index: 1;
        }
    }
    .close-input {
        position: absolute;
        right: 0px;
        right: 18px;
        top: 3px;
        z-index: 1;
        cursor: pointer;
    }
}

.first-row {
    width: 10% !important;
    padding-left: 0 !important;
    label input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid #9b9aa3;
        width: 14px !important;
        height: 14px;
        border-radius: 50%;
        position: relative;
        border-color: $button-color !important;
    }
    input:checked {
        position: relative;
        border-color: $button-color !important;
        border: 1px solid $button-color !important;
        &:after {
            top: 3px;
            left: 3px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: $button-color;
            content: "";
            position: absolute;
        }
    }
}
.add-qes {
    width: 90%;
    margin-left: 20px;
}
.add-qes-wrapper {
    font-family: Roboto;
    font-style: normal;
    background-color: #ffffff;
    padding-left: 52px;
    padding-bottom: 52px;
    padding-top: 25px;
    width: 68%;
    margin-left: 15px;
}

//add resource
.practise {
    font-family: Roboto;
    font-style: normal;
    background-color: #ffffff;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 90%;
    margin-left: 20px;
    small {
        color: red;
        font-size: 13px;
    }
    .addpointer {
        button {
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            width: unset;
            cursor: pointer;
            height: unset;
            padding: 11px 23px;
        }
    }
    .practice-form {
        margin-top: 31px !important;
        margin-bottom: 22px !important;
        input {
            height: 40px;
            border-radius: 4px;
        }
    }
    .span {
        color: $button-color;
        cursor: pointer;
    }
    button {
        background: $button-color;
        border: none;
        padding: 11px 50px;
        height: 40px;
        border-radius: 4px;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 18px;
        text-transform: uppercase;
        .arrow-right,
        .long-arrow-right {
            transform: rotate(135deg);
        }
        .arrow-right,
        .arrow-left,
        .long-arrow-right,
        .long-arrow-left {
            display: block;
            width: 7px;
            height: 7px;
            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
            margin-left: 24px;
        }
    }

    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #222222;
        position: relative;
        margin-left: -8px;
        img {
            vertical-align: middle;
            cursor: pointer;
        }
        .bc-nav {
            margin-top: -12px;
            .breadcrumb {
                padding: 0.75rem 0.7rem;
            }
        }
    }

    p {
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #9b9aa3;
        margin-top: 12px;
    }
    label {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
        width: 52%;
        aside {
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #9b9aa3;
        }
    }
    input {
        width: 52%;
        border-color: $chart-color;

        // &::-webkit-input-placeholder {
        //     color: #9b9aa3;
        //     font-size: 12px;
        //     text-align: center;
        // }
        // &::-moz-placeholder {
        //     color: #9b9aa3;
        //     font-size: 12px;
        //     text-align: center;
        // }
        // &:-ms-input-placeholder {
        //     color: #9b9aa3;
        //     font-size: 12px;
        //     text-align: center;
        // }
        // &::placeholder {
        //     color: #9b9aa3;
        //     font-size: 12px;
        //     text-align: center;
        // }
    }
    textarea {
        resize: none;
        width: 52%;
        border-color: $chart-color;
        height: 115px;
    }

    .duration {
        select {
            width: 12%;
            border-color: $chart-color;
        }
        input {
            width: 10%;
        }
    }
    .form-group {
        #subjects {
            background-color: #fff;
            border-color: $chart-color;
            height: 40px;
            margin-top: 9px;
            border-radius: 4px !important;
            padding: 0 15px;
            background-image: url("../../assets/select-indicator.svg");
            background-position: 96% center;
            // color: hsl(0, 0%, 50%) !important;
            option {
                color: #222222;
            }
        }
        .css-2b097c-container {
            .css-1okebmr-indicatorSeparator {
                display: none;
            }
        }
        .selectSub {
            margin-top: 9px;
            .css-b62m3t-container .css-13cymwt-control {
                .css-1u9des2-indicatorSeparator {
                    display: none;
                }
            }

            .css-13cymwt-control {
                border-color: $chart-color;
            }
        }
    }
}

.selected-count {
    margin: 20px 21px 12px 21px;
    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
    }
}
.arrow-down {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 9px solid #9b9aa3;
    top: -17px;
    right: -76px;
    border-radius: 3px;
}

.save-btn {
    margin-top: 30px;
    .ballbeat {
        position: relative;
        .ball-loader {
            width: 60px;
            height: 17.33333px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            .ball-loader-ball {
                width: 17.33333px;
                height: 17.33333px;
            }
        }
    }
    button {
        border: none;
        background-color: $button-color;
        width: 136px;
        height: 32px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        border-radius: 2px;
        &:nth-child(2) {
            border: 1px solid #f8f8f8;
            background-color: #ffffff;
            color: #222222;
            margin-left: 5px;
        }
    }
}

// quizlist
.managequiz {
    padding-right: 0px;
    width: 70%;
    margin-left: 20px;

    h1 {
        margin-left: 0.7rem;
    }
    .quizButton {
        position: relative;
        .arrow-right,
        .long-arrow-right {
            transform: rotate(135deg);
        }
        .arrow-right,
        .arrow-left,
        .long-arrow-right,
        .long-arrow-left {
            display: block;
            width: 7px;
            height: 7px;
            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
            margin-left: 24px;
            position: absolute;
            right: 35px;
        }
    }
    .content-header {
        padding-top: 33px;

        p {
            color: #696873;
            padding-left: 55px;
            font-size: 14px;
            font-weight: normal;
        }
        b {
            color: $button-color;
            font-weight: bold;
            margin-bottom: 20px;
            display: inline-block;
        }
    }
}
.css-b62m3t-container .css-13cymwt-control {
    .css-1u9des2-indicatorSeparator {
        display: none;
    }
}
.css-t3ipsp-control {
    .css-1u9des2-indicatorSeparator {
        display: none;
    }
}

//rte

.jodit-popup {
    z-index: 5 !important;
    transform: none !important;
}
.jodit-container {
    width: 65% !important;
    span {
        font-weight: normal;
        color: #222222;
        margin-bottom: 0px;
    }
    p {
        font-weight: normal;
        color: #222222;
        margin-top: 0px;
        font-size: 16px;
        margin: 2px;
    }
}
.jodit-toolbar__box {
    .jodit-ui-group__fullsize {
        display: none;
    }
}
.jodit-placeholder {
    color: #a5a5a5 !important;
    margin-bottom: 0px !important;
}
.jodit-workplace {
    .jodit-wysiwyg {
        // min-height: 50px !important;
        ul {
            margin-left: 20px !important;
            padding: 0px !important;
        }
        ol {
            margin-left: -20px !important;
        }
    }
}

.jodit-status-bar {
    display: none;
    .jodit-toolbar-button_size_tiny {
        display: none;
    }
}
.jodit-status-bar__item > span {
    color: #222222 !important;
    margin: 5px !important;
    font-family: Roboto !important;
}
.jodit-ui-group__superscript {
    display: block;
}

.options-holder {
    .jodit-container {
        width: 65% !important;
        // min-height: 0px !important;
        // height: 90px !important;
    }
}

.update-modal1 {
    width: 29%;
    .modal-body {
        label {
            margin-top: 10px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
        }
        input {
            border-color: $chart-color;
        }
        .css-2b097c-container {
            margin-top: 0.5rem;
            .css-1okebmr-indicatorSeparator {
                display: none;
            }
            .css-1pahdxg-control {
                border-color: $chart-color !important;
                box-shadow: 0px 0px 0px 1px $chart-color !important;
            }
            input:focus {
                box-shadow: none !important;
            }
            .css-yk16xz-control {
                border: 1px solid $chart-color;
                border-radius: 4px;
            }
        }
        small {
            color: red;
            font-size: 12px;
        }
    }
}

//add subject to classroom
.add-subject-to-classroom {
    width: 90%;
    margin-left: 20px;
    .add-header {
        background-color: #fff;
        padding: 25px 25px 13px 25px;
        img {
            width: 20px;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
            margin-top: 10px;
        }
        h2 {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #222222;
        }
        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            text-align: center;
        }
        button {
            border: none;
            background-color: $button-color;
            width: 132px;
            height: 34px;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            border-radius: 4px;
        }
    }
    .left-section {
        background-color: #fff;
        width: 100%;
        padding: 25px 55px;
        h1 {
            color: #484750;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
        }
        p {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $button-color;
            cursor: pointer;
        }
        span {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            cursor: grab;
        }
        .has-search {
            margin-top: 12px;
            position: relative;
            .form-control-feedback {
                position: absolute;
                z-index: 5;
                display: block;
                width: 2.375rem;
                height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: #aaa;
                margin-top: 3px;
                width: 24px;
                margin-left: 5px;
            }

            .form-control {
                padding-left: 1.9rem;
            }
            #search {
                background-color: #f7f7fa;
                border: none;
                &::-webkit-input-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &::-moz-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &:-ms-input-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &::placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
            }
        }
        .lists-of-subjects {
            .sub {
                // max-width: 205px;
                min-width: 112px;
                height: 32px;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                // display: flex;
                padding: 5px 10px;
                margin-right: 10px;
                // margin-bottom: 10px;
                overflow: hidden;
                height: 30px;
                text-align: center;
                background: #fff;
                margin: 3px;
                display: inline-block;
                cursor: grab;
                p {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #696873;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}

.breadcrumb {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 0rem;
    margin-bottom: 0rem;
    list-style: none;
    background-color: transparent !important;
    border-radius: 0.25rem;
    .breadcrumb-item {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

        margin-top: -8px;
        color: $button-color;
        a {
            color: #9a9a9a;
        }
    }
}
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    margin-top: -5px;
    content: ".";
}
.b-active {
    font-weight: 600 !important;
}
