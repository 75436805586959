@import "../../App.scss";

.cursor-pointer {
    cursor: pointer;
}

.achievers-holder {
    font-family: Roboto;
    font-style: normal;
    margin-left: 5px;
    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
    }
    .acheiver-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
    }
    .empty-list {
        width: 92%;
    }
    .achievers-tab {
        margin-bottom: 0;
        // background-color: #fff;
        overflow: visible;
        left: 0;
        margin-top: 10px;
        ul {
            overflow: auto;
            white-space: nowrap;
            // padding: 5px;
            width: 100%;
            list-style-type: none;
            border-bottom: 1px solid #dddde2;
            li {
                display: inline-block;
                margin-right: 10px;
                cursor: pointer;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                padding: 8px 20px;
                color: #484750;
                font-weight: 600;
                font-family: Roboto;
            }
            .active-tab {
                border-bottom: 4px solid $button-color;
            }
        }
    }
    .achievers-btns {
        margin-top: 10px;
        font-family: Roboto;
        h4 {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
        }
        button {
            margin-top: 19px;
            background: #f7f7fa;
            border: 1px solid #dddde2;
            color: #484750;
            font-weight: noraml;
            font-size: 14px;
            line-height: 18px;
            // border: none;
            padding: 7px 31px;
            border-radius: 4px;
        }
        .active-btn {
            background: $button-color;
            border: none;
            color: #fff;
            font-weight: bold;
        }
    }
    .achiever-overall {
        margin-top: 19px;
        margin-right: 20px;
        .card {
            text-align: center;
            .card-body {
                h4 {
                    font-size: 18px;
                    line-height: 20px;
                    color: #fff;
                    text-align: center;
                    font-weight: bold;
                    height: 40px;
                    small {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #fff;
                    }
                }
                .achievers-initial {
                    width: 56px;
                    height: 56px;
                    border-radius: 100px;
                    color: #fff;
                    // background-color: #fe912d;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 1;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 32px;
                    text-transform: uppercase;
                    img {
                        width: 56px;
                        height: 56px;
                        border-radius: 100px;
                        margin: 0 auto;
                    }
                }
                .under {
                    height: 50px;
                    width: 80px;
                    margin: 0 auto;
                    position: absolute;
                    top: 52px;
                }
                .achievers-name {
                    text-align: center;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    margin-top: 4px;
                    height: 29px;
                    span {
                        color: #fff;
                    }
                }
                .achievers-rank {
                    background-color: $chart-color;
                    width: 72px;
                    border-radius: 4px 4px 0px 0px;
                    margin-top: 10px;
                    padding: 6px;
                    padding-bottom: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    p {
                        font-family: Lato;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #fff;
                    }
                }
                .you {
                    width: 40px;
                    height: 20px;
                    border-radius: 7px;
                    background-color: $success;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: -5px;
                    p {
                        text-align: center;
                        color: #fff;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 15px;
                        padding-top: 2px;
                    }
                }
                .achievers-score {
                    display: flex;
                    justify-content: center;
                    margin-top: 5px;
                    .rank {
                        h4 {
                            font-family: Lato;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0px;
                            text-align: left;
                            width: 48px;
                            height: 48px;
                            border-radius: 100px;
                            background-color: $dark-background;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                        }
                        p {
                            font-weight: normal;
                            color: #fff;
                            font-size: 12px;
                            line-height: 15px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        p {
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: $button-color;
            margin-top: 15px;
        }
    }
    .section-details {
        margin-top: 10px;
        h4 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: $dark-background;
        }
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
        }
    }
    .achievers-section {
        margin-top: 19px;
        font-family: Roboto;
        font-style: normal;
        display: flex;

        .achievers-section-card {
            width: 60%;
            .card {
                background-color: $darker-background;
                margin-right: 7px;
                width: 12.4rem;
                position: relative;
                cursor: pointer;
                .card-overlay {
                    position: absolute;
                    height: 150px;
                    width: 100%;
                    z-index: 2;
                }
                .card-body {
                    padding: 1rem;
                    p {
                        position: relative;
                        margin-top: 0;
                        margin-bottom: 5px;
                        z-index: 1;
                        .initial {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            vertical-align: middle;
                            border-style: none;
                            background-color: orange;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0 auto;
                            color: white;
                            font-weight: bold;
                            font-size: 32px;
                            line-height: 40px;
                            text-transform: uppercase;
                        }
                        img {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            vertical-align: middle;
                            border-style: none;
                        }
                    }
                    .medal1 {
                        position: absolute;
                        top: 60px;
                        right: 40px;
                        z-index: 1;
                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .under {
                        height: 50px;
                        width: 80px;
                        margin: 0 auto;
                        position: absolute;
                        top: 13px;
                        left: 38px;
                    }
                    h4 {
                        color: #fff;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        p {
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            color: #9b9aa3;
                        }
                        span {
                            color: #fff;
                        }
                    }
                    h5 {
                        font-family: Lato;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0px;
                        // text-align: left;
                        color: #fff;
                        display: inline-block;
                        vertical-align: -webkit-baseline-middle;
                        margin-left: 35px;
                    }
                    h6 {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: $button-color;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 20px;
                        }
                    }
                }
            }
            .user-lists {
                // width: 610px;
                width: 98%;
                ul {
                    list-style: none;
                    li {
                        cursor: pointer;
                        .user-overlay {
                            position: absolute;
                            width: 540px;
                            height: 50px;
                        }
                        display: flex;
                        background-color: #fff;
                        align-items: center;
                        border-top: 5px solid #f6f6f6;
                        .rank-no {
                            width: 15%;
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 24px;
                            line-height: 20px;
                            color: $dark-background;
                            padding: 18px;
                            padding-left: 25px;
                        }
                        .ranker-name {
                            display: flex;
                            width: 65%;
                            align-items: center;
                            .initial {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                // background-color: #fe912d;
                                color: #fff;
                                font-weight: bold;
                                font-size: 18px;
                                line-height: 24px;
                                text-transform: uppercase;
                                img {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    vertical-align: middle;
                                    border-style: none;
                                }
                            }
                            .name {
                                h1 {
                                    font-weight: bold;
                                    font-size: 14px;
                                    line-height: 18px;
                                    color: #696873;
                                }
                                p {
                                    font-weight: normal;
                                    font-size: 12px;
                                    line-height: 15px;
                                    color: #9b9aa3;
                                }
                            }
                        }
                        .ranker-score {
                            width: 15%;
                            font-family: Lato;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $dark-background;
                        }
                        .clap {
                            width: 10%;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .achievers-section-right {
            background: #ffffff;
            width: 30.5%;
            position: absolute;
            right: 0px;
            top: 75px;
            // height: 100vh;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            .right-part {
                position: sticky;
                position: -webkit-sticky;
                right: 0;
                top: 75px;
                // width: 300px;
                overflow-y: scroll;
                // max-height: calc(100vh - 1rem);
                padding: 20px 28px;
                & ::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: white;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #ffffff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    max-height: 10px;
                    background-color: #e9e8e8;
                }
                h1 {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 32px;
                    color: #484750;
                }
                .ranker-details {
                    margin-top: 20px;
                    background-color: #edf3f3;
                    padding: 28px 16px;
                    border-radius: 4px;
                    color: #fff;
                    h4 {
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 24px;
                        color: #484750;
                    }
                    p {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #9b9aa3;
                    }
                    .initial {
                        width: 130px;
                        height: 130px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // background-color: #fe912d;
                        color: #fff;
                        font-weight: bold;
                        font-size: 30px;
                        line-height: 24px;
                        text-transform: uppercase;
                        img {
                            width: 130px;
                            height: 130px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    h2 {
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        color: $button-color;
                        margin-top: 4px;
                    }
                    .right-part-rank {
                        padding: 12px 20px;
                        border-radius: 4px;
                        background-color: $dark-background;
                        width: 45%;
                        margin-top: 3px;
                        small {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                            font-weight: normal;
                            font-size: 24px;
                            line-height: 20px;
                            p {
                                font-weight: normal;
                                margin-top: 5px;
                                font-size: 12px;
                                line-height: 15px;
                                color: #fff;
                            }
                        }
                    }
                }
                .subject-chart {
                    margin-left: -8px;
                }
                .subject-wise-graph {
                    margin-top: 20px;
                    h1 {
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 24px;
                        color: $dark-background;
                    }
                    p {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #9b9aa3;
                    }
                    .legend-box {
                        display: flex;
                        margin-top: 15px;
                        .legend {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            color: #696873;
                            margin-right: 12px;
                            .legend-circle {
                                width: 12px;
                                height: 12px;
                                border-radius: 50%;
                            }
                        }
                    }
                    .highcharts-axis-line {
                        display: none !important;
                    }
                    .highcharts-label text {
                        font-family: Lato;
                        font-size: 14px !important;
                        font-weight: 700 !important;
                    }
                    .engagement-update {
                        z-index: 1;
                        position: absolute;
                        top: 55%;
                        left: 31%;
                        transform: translate(-20%, -50%);
                        //    margin-bottom: 20px;
                        text-align: center;
                        color: #9b9aa3;
                        font-size: 14px;
                    }
                }
                .month-wise-graph {
                    h1 {
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 24px;
                        color: $dark-background;
                    }
                    p {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #9b9aa3;
                    }
                    .engagement-update {
                        z-index: 1;
                        margin-top: 60px;
                        margin-bottom: 80px;
                        text-align: center;
                        color: #9b9aa3;
                        font-size: 14px;
                    }
                }
                .achievers-navigation {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #696873;
                        margin-bottom: 5px;
                    }
                    .prev {
                        padding: 8px;
                        background-color: #f7f7fa;
                        border-radius: 4px;
                        display: flex;
                        .intial {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: $dark-background;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                        }
                        p {
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 18px;
                            color: #696873;
                            small {
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 15px;
                                color: #9b9aa3;
                            }
                        }
                    }
                }
            }
        }
    }
}

.overflowY-hidden {
    overflow-y: hidden !important;
}
@media (max-width: 767px) {
    .achievers-holder {
        width: 93%;
        margin-left: 10px;
        margin-top: 0px;
        margin-bottom: 60px;
        .achievers-tab {
            ul {
                li {
                    padding: 8px 14px;
                    margin-right: 0px;
                }
            }
        }
        .achievers-btns {
            margin-top: 0px;
        }
        .achiever-overall {
            margin-right: 15px;
            .card {
                width: 98% !important;
            }
            p {
                margin-top: 0px;
            }
        }
        .achievers-section {
            // display: none;
            .achievers-section-card {
                width: 100%;
                .card {
                    min-width: 13.4rem;
                    &:last-of-type {
                        margin-right: 20px;
                    }
                    .card-body {
                        .medal1 {
                            top: 10px;
                            right: unset;
                            left: 5px;
                        }
                    }
                }
                .user-lists {
                    width: 104%;
                    margin-left: -15px;
                    ul {
                        li {
                            .user-overlay {
                                width: 325px;
                            }
                        }
                    }
                }
            }
            .achievers-section-right {
                display: none;
            }
        }
    }
}

.mobile-drawer1 {
    .MuiDrawer-paperAnchorBottom {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        height: 100vh !important;
        .right-part {
            overflow-y: scroll;
            // max-height: calc(100vh - 2rem);
            padding: 20px;
            h1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                color: #484750;
            }
            .ranker-details {
                margin-top: 20px;
                background-color: #edf3f3;
                padding: 24px 12px;
                border-radius: 4px;
                color: #fff;
                h4 {
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 24px;
                    color: #484750;
                }
                p {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #9b9aa3;
                }
                .initial {
                    width: 130px;
                    height: 130px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // background-color: #fe912d;
                    color: #fff;
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 24px;
                    text-transform: uppercase;
                    img {
                        width: 130px;
                        height: 130px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                h2 {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $button-color;
                    margin-top: 4px;
                }
                .right-part-rank {
                    padding: 12px 20px;
                    border-radius: 4px;
                    background-color: $dark-background;
                    width: 45%;
                    margin-top: 3px;
                    small {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 20px;
                        p {
                            font-weight: normal;
                            margin-top: 5px;
                            font-size: 12px;
                            line-height: 15px;
                            color: #fff;
                        }
                    }
                }
            }
            .subject-chart {
                margin-left: -8px;
            }
            .subject-wise-graph {
                margin-top: 20px;
                position: relative;
                h1 {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 24px;
                    color: $dark-background;
                }
                p {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #9b9aa3;
                }
                .legend-box {
                    display: flex;
                    margin-top: 15px;
                    .legend {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #696873;
                        margin-right: 12px;
                        .legend-circle {
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                        }
                    }
                }
                .highcharts-axis-line {
                    display: none !important;
                }
                .highcharts-label text {
                    font-family: Lato;
                    font-size: 14px !important;
                    font-weight: 700 !important;
                }
                .engagement-update {
                    z-index: 1;
                    position: absolute;
                    top: 55%;
                    left: 20%;
                    transform: translate(-20%, -50%);
                    //    margin-bottom: 20px;
                    text-align: center;
                    color: #9b9aa3;
                    font-size: 14px;
                    width: 100%;
                }
            }
            .month-wise-graph {
                h1 {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 24px;
                    color: $dark-background;
                }
                p {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #9b9aa3;
                }
                .engagement-update {
                    z-index: 1;
                    margin-top: 60px;
                    margin-bottom: 80px;
                    text-align: center;
                    color: #9b9aa3;
                    font-size: 14px;
                }
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .achievers-holder {
        .achiever-overall .card .card-body .achievers-rank {
            width: 45px;
        }
        .achievers-section {
            .achievers-section-card {
                width: 80%;
                .user-lists {
                    width: 100%;
                }
            }
            .achievers-section-right {
                display: none;
            }
        }
    }
}
