@import "../../App.scss";

.manage-subtopics {
    width: 90%;
    margin-left: 20px;
    position: relative;
    .subject-header {
        background-color: #fff;
        padding: 14px;
        border-bottom: 2px solid $chart-color;

        .subject-btn {
            margin-top: 0px;
            li {
                display: inline-block;
                button {
                    background: $button-color;
                    border: none;
                    width: 134px;
                    height: 40px;
                    border-radius: 4px;
                    color: #fff;
                    font-weight: bold;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 18px;
                    margin-left: 5px;
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        ul {
            list-style: none;
            margin-top: 30px;
            li {
                display: flex;
                // text-align: center;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: $chart-color;
                cursor: default;
                div:first-child {
                    width: 10%;
                    padding-left: 32px;
                    color: $chart-color;
                }
                div:nth-child(2) {
                    width: 30%;
                    color: $chart-color;
                    margin-left: 35px;
                }
                div {
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        width: 20%;
                        color: $chart-color;
                        text-align: center;
                    }
                }
            }
        }
    }
    .subject-list {
        list-style: none;
        margin-top: 7px;
        font-family: Roboto;
        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            padding: 13px 22px;
            cursor: default;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            background-color: #fff;
            margin-bottom: 8px;
            h3 {
                font-weight: normal;
                font-size: 14px;
                line-height: 14px;
                color: #696873;
                font-family: Roboto;
            }
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                .react-switch-handle {
                    height: 12px !important;
                    width: 12px !important;
                    top: 2px !important;
                }
            }
            .subject-name {
                width: 35%;
                margin-left: -10px;
                img {
                    display: inline-block;
                    vertical-align: middle;
                }
                p {
                    display: inline-block;
                    margin-left: 10px;
                }
            }
            .subject-num {
                width: 10%;
                padding-left: 30px;
                img {
                    display: inline-block;
                    vertical-align: bottom;
                    margin-left: -35px;
                }
                p {
                    display: inline-block;
                    margin-left: 12px;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 24px;
                    color: #696873;
                    font-family: Lato;
                }
            }
            div {
                &:nth-child(3),
                &:nth-child(4) {
                    width: 20%;
                }
                img {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }
            div:nth-child(5) {
                width: 20%;
                text-align: center;
            }
        }
    }
}
.ql-flip {
    left: 0px !important;
    top: 0px !important;
}

.save-modal {
    width: 25%;
    .buble {
        margin-left: 24px;
        cursor: pointer;
        p {
            font: normal normal 600 19px/27px Roboto;
            letter-spacing: 0px;
            color: $button-color;
            text-transform: capitalize;
        }
    }
    .hr {
        margin-bottom: 8px;
    }
    #scrollableDiv1 {
        & ::-webkit-scrollbar-track {
            border-radius: 0px;
            background-color: white;
        }
        &::-webkit-scrollbar {
            width: 8px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
            max-height: 0px;
            background-color: #e9e8e8;
        }
        scrollbar-width: thin;
    }
    .bubbleList {
        padding: 4px 25px;
        list-style: none;

        .save-content {
            position: relative;
            p {
                text-align: left;
                font: normal normal 600 15px/20px Roboto;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }
            label {
                text-align: left;
                font: normal normal normal 16px/17px Roboto;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }
            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                position: absolute;
                right: -15px;
                width: 20px;
                height: 20px;
            }
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: #666;
                margin-right: 14px;
            }
            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 18px;
                height: 18px;
                border: 1px solid #ddd;
                border-radius: 100%;
                background: #fff;
            }

            // Radio custom
            [type="radio"]:checked:after,
            [type="radio"]:not(:checked) + label:after {
                content: "";
                width: 12px;
                height: 12px;
                background: $button-color !important;
                position: absolute;
                top: 4px;
                left: 4px;
                border-radius: 100%;
                border: 1px solid $button-color;
                // -webkit-transition: all 0.2s ease;
                // transition: all 0.2s ease;
                font-family: "Roboto";
            }
        }
    }
}

@media (max-width: 767px) {
    .save-modal {
        width: unset;
        .bubbleList .save-content [type="radio"]:checked,
        .bubbleList .save-content [type="radio"]:not(:checked) {
            right: -5px;
        }
    }
}
