@import "../../App.scss";

.counselling-holder {
    margin-left: 10px;
    margin-bottom: 20px;
    width: 92%;
    .counselling-introduction {
        background-color: #c0beff33;
        min-height: 244px;
        padding: 25px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        flex-wrap: wrap;
        position: relative;
        .introduction {
            width: 50%;
            h3 {
                //styleName: Sub headings;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #413c69;
            }
            p {
                padding: 7px 0px;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #272727;
            }
            .counselling-btn {
                padding-top: 8px;
                button {
                    border: none;
                    color: #fff;
                    background-color: $button-color;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    height: 45px;
                    border-radius: 4px;
                    padding: 5px 13px;
                    &:nth-child(2) {
                        margin-left: 8px;
                        color: $button-color;
                        background-color: transparent;
                        border: 1px solid $button-color;
                    }
                }
            }
            .report-btns {
                padding-top: 8px;
                button {
                    border: 1px solid $button-color;
                    color: $button-color;
                    background-color: transparent;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    height: 45px;
                    border-radius: 4px;
                    padding: 5px 20px;
                    &:nth-child(2) {
                        border: none;
                        margin-left: 8px;
                        color: $button-color;
                        background-color: transparent;
                        text-decoration: underline !important;
                    }
                }
            }
        }
        .career-codes {
            width: 67%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .career-code {
                min-height: 182px;
                padding: 20px;
                // background-color: #f2f2ff;
                width: 100%;
                display: flex;
                // justify-content: center;
                align-items: center;
                margin-left: 20px;
                p {
                    //styleName: Body text;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #272727;
                }
                h6 {
                    //styleName: Sub headings;
                    font-family: Roboto;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                }
                .progress-circular {
                    margin-left: 40px;
                    width: 30%;
                }
            }
        }
        .counselling-img {
            width: 50%;
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }
    .how-to-works {
        margin-top: 10px;
        h4 {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: $heading;
        }
        img {
            width: 300px;
            height: 218px;
        }
        h5 {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 35px;
            letter-spacing: 0px;
            text-align: left;
            color: #1d1d1d;
            margin-top: -10px;
        }
        p {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: left;
            color: #272727;
        }
    }
    .why-counselling {
        margin-top: 25px;
        position: relative;
        padding: 20px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .introduction {
            width: 50%;
            h3 {
                //styleName: Sub headings;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #413c69;
            }
            p {
                // padding: 7px 0px;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: $heading;
            }
            .counselling-points {
                margin-top: 10px;
                div {
                    margin-bottom: 10px;
                }
                h6 {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 27px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #272727;
                    margin-left: 15px;
                }
            }
        }
        .counselling-img {
            width: 50%;
            // position: absolute;
            top: 0px;
            right: 0px;
            // img {
            //     width: 514px;
            //     height: 230px;
            // }
        }
    }
    .bookings {
        h3 {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: $heading;
        }
        button {
            border: none;
            background-color: $button-color;
            color: #fff;
            width: 110px;
            height: 38px;
        }
        .booking-tabs {
            margin-top: 25px;
            display: flex;
            list-style: none;
            li {
                margin-right: 32px;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #333269;
                padding-bottom: 8px;
                cursor: pointer;
                &.active {
                    border-bottom: 3px solid #5855d6;
                    font-weight: 800;
                }
            }
        }
        .booking-list {
            margin-top: 15px;
            .booking-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #0000001a;
                padding: 12px;
                background-color: #fff;
                margin-top: 5px;
                div {
                    width: 25%;
                    h4 {
                        font-family: Roboto;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 32px;
                        letter-spacing: 0.10000000149011612px;
                        text-align: left;
                        color: #413c69;
                    }
                    p {
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0.10000000149011612px;
                        text-align: center;
                        color: #413c69;
                        text-decoration: underline !important;
                    }
                }
            }
        }
    }
    .report-table {
        margin-top: 10px;
        h3 {
            //styleName: Sub headings;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: $heading;
        }
        .table {
            margin-top: 10px;
            background: #fff;
            margin-bottom: 0px;
            thead th {
                text-align: center;
                padding: 13px;
                color: $heading;
                background: #c0beff33;
            }
            tbody {
                tr {
                    .empty-list {
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        padding: 40px;
                        margin-top: 8px;
                        width: 100%;
                        // position: absolute;
                        img {
                            width: 350px;
                            height: 210px;
                        }
                        p {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            margin-top: 15px;
                            color: #696873;
                        }
                    }
                    td {
                        vertical-align: middle;
                        border-top: 15px solid #fff !important;
                        text-align: center;
                        padding: 12px;
                        p {
                            text-decoration: underline !important;
                        }
                    }
                }
            }
        }
    }
}
.ability-holder {
    width: 86%;
    margin-left: 5px;
    background-color: #fff;
    .ability-header {
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 55%;
        -webkit-align-items: center;
        align-items: center;
        img {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        p {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #000000;
        }
    }
    .table-admin {
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        background: #fff;
        // margin-top: 40px;
        width: 88.5%;
        .table {
            margin-bottom: 0px;
            thead {
                hr {
                    margin-bottom: -1rem;
                    border-top: none;
                    margin-right: 0px;
                    margin-top: 0px;
                }
                tr {
                    th {
                        // box-shadow: 4px 0 6px -4px rgba(0, 0, 0, 0.25);
                        border-bottom: 2px solid $chart-color;
                        border-top: none;
                        color: $chart-color;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        text-align: left;
                    }
                }
            }

            tbody {
                tr {
                    .empty-list {
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        padding: 40px;
                        margin-top: 8px;
                        width: 67.7%;
                        position: absolute;
                        img {
                            width: 350px;
                            height: 210px;
                        }
                        p {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            margin-top: 15px;
                            color: #696873;
                        }
                    }
                    td {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                        vertical-align: middle;
                        border-top: 8px solid #f6f6f6 !important;
                        text-align: left;
                        border-bottom: 0px;
                        .intial {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            background-color: $dark-background;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                            img {
                                width: 30px;
                                height: 30px;
                            }
                        }
                        p {
                            color: #696873;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            span {
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 15px;
                                color: #9b9aa3;
                            }
                        }
                        .arrow-rotate {
                            width: 16px;
                            height: 16px;
                            transform: rotate(90deg);
                            cursor: pointer;
                        }
                    }
                    .action {
                        cursor: pointer;
                        img {
                            display: inline-block;
                            vertical-align: middle;
                        }
                        button {
                            border: none;
                            background: none;
                            //    &:hover{
                            //        background: #DDDDE2;
                            //        width: 30px;
                            //        height: 30px;
                            //        border-radius: 50%;

                            //    }
                        }
                    }
                }
            }
        }
    }
    .career-interest-progress {
        width: 100%;
        display: flex;
        h6 {
            // margin-top: 20px;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
        }
        p {
            margin-top: 10px;
            text-align: left;
        }
    }
    .t-ability {
        h4 {
            font-family: Roboto;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #272727;
        }
        ul {
            margin-top: 10px;
            // list-style: none;
            padding-left: 20px;
            li {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
}
.question-modal {
    position: relative;
    .modal-content {
        background-color: #f6f6f6;
    }
    .questions-planning {
        .add-header {
            background-color: #fff;
            padding: 18px 25px;
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .timer {
                background-color: #f7f7fa;
                img {
                    margin-top: 0px !important;
                }
            }
            img {
                width: 20px;
                cursor: pointer;
                display: inline-block;
                vertical-align: top;
                margin-top: 5px;
            }
            h1 {
                font-family: "Lato";
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 20px;
                color: #222222;
            }
            h2 {
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                color: #222222;
            }
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                text-align: center;
            }
            button {
                border: none;
                background-color: $button-color;
                width: 200px;
                height: 40px;
                color: #fff;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                border-radius: 0px;
            }
            .close-icon {
                width: 15px !important;
                margin-bottom: 5px;
            }
        }
        .schedule-list {
            max-height: 500px;
            overflow: auto;
            .form-check-input {
                margin-top: 0.1em !important;
            }
            .no-extra {
                margin-right: 4px;
                &:nth-child(3n) {
                    margin-right: 0px;
                }
            }
            .extra {
                margin-right: 4px;
                &:nth-child(4n) {
                    margin-right: 0px !important;
                }
            }
            .marks-box {
                height: 40px;
                background-color: #fff;
                // margin-bottom: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 5px;
                p {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #696873;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 5px;
                    margin-bottom: 4px;
                }
                select {
                    cursor: pointer;
                    border: none !important;
                    box-shadow: none !important;
                    background-image: url("../../assets/select-indicator.svg");
                    background-position: 100% center;
                    height: 15px;
                }
                .form-control {
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 14px;
                    color: #696873;
                    padding: 0px;
                }
                .form-check-inline {
                    margin-right: 0.5rem;
                    margin-bottom: 0px;
                }
                h2 {
                    input {
                        background-color: #f7f7fa;
                        width: 114px;
                        height: 33px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.edit-schedule-modal {
    width: 35%;
    .modal-content {
        border-radius: 4px;
        border: none;
    }
    .modal-header {
        position: relative;
        // padding: 0.5rem 0.5rem;
        text-align: center;
        display: block;
        h5 {
            color: #fff;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 22px;
        }
        .sticky-close {
            position: absolute;
            right: 13px;
            top: 22px;
            cursor: pointer;
        }
    }
    .modal-body {
        label {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 100%;
        }
        input {
            border-color: $chart-color;
        }

        small {
            font-size: 12px;
            color: red;
        }
        .css-yk16xz-control {
            border-color: $chart-color;
        }
        .css-1pahdxg-control {
            border-color: $chart-color;
            box-shadow: none;
        }
    }
    .remove-btn {
        position: absolute;
        right: -20px;
        top: 6px;
        background: transparent;
        border: none;
    }
    .add-btn {
        background-color: transparent;
        color: $button-color;
        border: none;
    }
}

.self-assessment-test-view {
    margin-right: 0;
    margin-top: 0 !important;
    margin-bottom: 0;
    width: 100vw;
    min-height: calc(100% - 0rem);
    max-height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    background: #fff;
    overflow: auto;
    &::before {
        background: transparent;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        content: "";
        // width: calc(100vw - 0px);
    }
    .ques-header {
        border-radius: 20px;
        background-color: #3f51b5;
        color: #fff;
        width: 168px;
        height: 40px;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .question-div {
        margin-top: -15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #e9e6fd;
        border-radius: 10px;
        padding-bottom: 50px;
        h6 {
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #000000;
        }
        h5 {
            font-family: Roboto;
            font-size: 18px;
            margin-top: 40px;
            text-align: center;
            height: 42px;
        }
        h4 {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: center;
            color: #272727;
            margin-top: 20px;
        }
        .question-options {
            margin-top: 50px;
            display: flex;
            align-items: center;
            overflow: auto;
            overflow-x: hidden;
            max-height: 370px;
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            .active {
                img {
                    filter: grayscale(0%) !important;
                }
            }
            .question-option {
                margin-right: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .circle {
                    width: 13px;
                    height: 13px;
                    opacity: 0.2;
                    border-radius: 50%;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
                img {
                    width: 62px;
                    height: 62px;
                    filter: grayscale(100%);
                }
                p {
                    font-family: Roboto;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: 0em;
                    text-align: center;
                    min-height: 40px;
                    color: #000000;
                }
            }
            .skill {
                margin-top: 10px;
                padding: 5px 15px;
                border: 1px solid #5855d6;
                min-height: 42px;
                border-radius: 12px;
                font-family: Roboto;
                font-size: 18px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: center;
                color: #000000;
                margin-right: 10px;
            }
            .active-opt {
                background-color: #5855d6;
                color: #fff;
            }
        }
        .options {
            list-style: none;
            margin-top: 10px;
            margin-right: -50px;
            li {
                width: 100%;
                // display: flex;
                // justify-content: center;
                align-items: center;
            }
            .ans-color {
                background-color: #5855d6;
                color: #fff !important;
                p {
                    color: #fff !important;
                }
            }
            .option {
                border-radius: 8px;
                border: 1px solid #5855d6;
                width: 90%;
                min-height: 40px;
                margin-top: 10px;
                box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                padding: 5px;
                margin-left: 0px;
                cursor: pointer;
                margin-right: -10px;
                color: #000000;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p {
                    color: #000000;
                    padding: 5px;
                    span {
                        color: #000000;
                    }
                    img {
                        vertical-align: middle;
                    }
                }
            }

            .option1 {
                border-radius: 8px;
                border: 1px solid #5855d6;
                width: 70%;
                min-height: 40px;
                margin-top: 10px;
                box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                padding: 5px;
                // margin-left: 100px;
                cursor: pointer;
                color: #000000;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p {
                    padding: 5px;
                    color: #000000;
                    span {
                        color: #000000;
                    }
                    img {
                        vertical-align: middle;
                        &:hover {
                            -ms-transform: scale(4); /* IE 9 */
                            -webkit-transform: scale(4); /* Safari 3-8 */
                            transform: scale(4);
                            position: relative;
                            /* right: -380px; */
                            z-index: 9999;
                        }
                    }
                }
            }
        }
    }
    .action-btns {
        margin-top: 20px;
        z-index: 2;
        button {
            width: 148px;
            height: 40px;
            border-radius: 4px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: center;
            background-color: #e9e6fd;
            color: #5855d6;
            border: none;
        }
    }
    .instruction-holder {
        background-color: #fff;
        box-shadow: 0px 3px 25px 0px #3c4dac1a;
        padding: 30px;
        display: flex;

        h3 {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: $heading;
        }
        p {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #272727;
        }
        ul {
            margin-top: 20px;
            li {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #272727;
            }
        }
        button {
            background-color: $button-color;
            color: #fff;
            border: none;
            height: 40px;
            width: 150px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
        }
    }
}

.test-report-holder {
    margin-left: 10px;
    margin-bottom: 20px;
    width: 92%;
    padding: 20px;
    box-shadow: 0px 3px 25px 0px #3c4dac1a;
    h2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //styleName: Sub headings;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $heading;
        .btns {
            button {
                background-color: transparent;
                color: $button-color;
                border: 1px solid $button-color;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: center;
                padding: 3px 16px;
                border-radius: 4px;
                &:nth-child(2) {
                    margin-left: 10px;
                    background-color: $button-color;
                    color: #fff;
                    border: none;
                }
            }
        }
    }
    .user-details {
        h5 {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: $heading;
        }
    }
    .career-header {
        background-color: #3f51b5;
        padding: 12px;
        color: #fff;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        border-radius: 4px;
        margin-top: 15px;
    }
    .career-interests {
        border: 1px solid #0000001a;
        background-color: #fff;
        padding: 20px 30px;
        margin-top: 12px;
        p {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #272727;
        }
        .career-interest-progress {
            width: 100%;
            display: flex;
            .progress-circular {
                position: relative;
                width: 100%;
                min-height: 170px;
                // margin-left: 25%;
            }
            h6 {
                margin-top: 20px;
                font-family: Roboto;
                font-size: 18px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
            }
            p {
                margin-top: 10px;
                text-align: left;
            }
        }
    }
    .suggested-career {
        margin-top: 10px;
        h4 {
            //styleName: Course card heading;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: $heading;
        }
        p {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #272727;
        }
        h5 {
            //styleName: Section heading;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: $heading;
        }
        .preferences {
            display: flex;
            .preference {
                // height: 74px;
                background: #c0beff33;
                padding: 10px;
                margin-right: 20px;
                h5 {
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #272727;
                }
                h6 {
                    margin-top: 7px;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #272727;
                }
            }
        }
        .suggested-jobs {
            display: flex;
            flex-wrap: wrap;
            .suggested-job {
                width: 32%;
                background-color: #f1feeb;
                padding: 10px;
                display: flex;
                // align-items: center;
                margin-right: 10px;
                margin-top: 10px;
                h6 {
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                }
                p {
                    //styleName: caption;
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #272727;
                }
            }
        }
        .div1 {
            // background: #ffffff;
            // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            .elite-text-vertical {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #f1feeb;
                width: 50px;
                padding: 22px;
                .characters {
                    display: block;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 32px;
                    letter-spacing: 1.95em;
                    text-align: left;
                    color: #35996d;
                    // opacity: 0.5;
                    transform: rotate(-90deg);
                }
            }
            .suggest-job-roles {
                background-color: #f1feeb;
                margin-left: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                p {
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #272727;
                }
                .job-roles {
                    width: 60%;

                    h6 {
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 32px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #454849;
                    }
                    .job-div {
                        background: #ceffd3;
                        margin-top: 10px;
                        // height: 39px;
                        border: 1px solid #35996d;
                        color: #35996d;
                        //styleName: Section heading;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 32px;
                        letter-spacing: 0em;
                        text-align: left;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                    .job-div-i {
                        margin-top: 10px;
                        background: #cef4ff;
                        // height: 39px;
                        border: 1px solid #1c809d;
                        color: #1c809d;
                        //styleName: Section heading;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 32px;
                        letter-spacing: 0em;
                        text-align: left;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                    .job-div-a {
                        margin-top: 10px;
                        background: #cee5ff;
                        // height: 39px;
                        border: 1px solid #1c809d;
                        color: #1c809d;
                        //styleName: Section heading;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 32px;
                        letter-spacing: 0em;
                        text-align: left;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                    .job-div-s {
                        margin-top: 10px;
                        background: #ffd1d1;
                        // height: 39px;
                        border: 1px solid #dc5e5f;
                        color: #dc5e5f;
                        //styleName: Section heading;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 32px;
                        letter-spacing: 0em;
                        text-align: left;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                    .job-div-e {
                        margin-top: 10px;
                        background: #ffdccb;
                        // height: 39px;
                        border: 1px solid #ee824b;
                        color: #ee824b;
                        //styleName: Section heading;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 32px;
                        letter-spacing: 0em;
                        text-align: left;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                    .job-div-c {
                        margin-top: 10px;
                        background: #fff7d0;
                        // height: 39px;
                        border: 1px solid #dec02e;
                        color: #dec02e;
                        //styleName: Section heading;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 32px;
                        letter-spacing: 0em;
                        text-align: left;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                }
            }
        }
    }
    .other-interest {
        padding: 10px;
        h5 {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #dc5e5f;
        }
        p {
            //styleName: Body text;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #272727;
        }
    }
}

.other-reports {
    background-color: #fff;
    padding: 15px;
    h2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //styleName: Sub headings;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $heading;
        .btns {
            button {
                background-color: transparent;
                color: $button-color;
                border: 1px solid $button-color;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: center;
                padding: 3px 16px;
                border-radius: 4px;
                &:nth-child(2) {
                    margin-left: 10px;
                    background-color: $button-color;
                    color: #fff;
                    border: none;
                }
            }
        }
    }
}

.schedule-modal {
    width: 370px;
    .modal-content {
        border-radius: 4px;
        .modal-header {
            padding: 18px 15px;
            border-bottom: 1px solid #cac4d0;

            .modal-title {
                font-family: Roboto;
                font-size: 20px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                color: #1d1b20;
            }
            .sticky-close {
                position: absolute;
                right: 13px;
                top: 22px;
                cursor: pointer;
            }
        }
        .modal-body {
            .date-modal {
                h2 {
                    text-align: left;
                    font: normal normal 600 18px/24px Roboto;
                    letter-spacing: 0px;
                    color: #484750;
                    opacity: 1;
                }
                .react-calendar__navigation {
                    background-color: none;
                }
                .react-calendar {
                    border: none;
                    font-family: Roboto;

                    .react-calendar__month-view__weekdays__weekday {
                        //styleName: M3/body/large;
                        font-family: Roboto;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        text-align: center;
                        color: #1d1b20;
                    }
                    .react-calendar__navigation {
                        button {
                            min-width: 44px;
                            background: none;
                            font-size: 30px;
                        }
                        .react-calendar__navigation__label {
                            position: absolute;
                            top: 30px;
                            font-size: 16px;
                            left: 30px;
                            pointer-events: none;
                            //styleName: M3/title/small;
                            font-family: Roboto;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0.10000000149011612px;
                            text-align: left;
                            color: #49454f;
                            &:hover {
                                background-color: none !important;
                            }
                        }
                        button[disabled] {
                            background: none;
                        }
                        button:enabled:hover {
                            background-color: #fff !important;
                        }
                        button:enabled:focus {
                            background-color: #fff !important;
                        }
                        .react-calendar__navigation__prev-button {
                            margin-left: 170px;
                            &:hover {
                                background-color: #fff !important;
                            }
                        }
                        .react-calendar__navigation__prev2-button {
                            display: none !important;
                        }
                        .react-calendar__navigation__next2-button {
                            display: none !important;
                        }
                    }
                    .react-calendar__viewContainer {
                        margin-top: -15px;
                        .react-calendar__month-view__weekdays__weekday {
                            abbr[data-original-title],
                            abbr[title] {
                                cursor: unset !important;
                            }
                        }
                        .react-calendar__month-view__days {
                            .react-calendar__tile:disabled {
                                width: 45px;
                                height: 45px;
                                background: none;
                            }
                            .react-calendar__tile:enabled:hover {
                                width: 45px;
                                height: 45px;
                                border-radius: 50%;
                            }
                            .react-calendar__tile .react-calendar__tile--now .react-calendar__month-view__days__day {
                                background: transparent !important;
                            }
                            .react-calendar__tile--now {
                                width: 45px;
                                height: 45px;
                                background: transparent !important;
                            }
                            .react-calendar__month-view__days__day--weekend {
                                color: #000000 !important;
                                &:disabled {
                                    color: #1010104d !important;
                                }
                            }
                            .react-calendar__tile {
                                width: 45px;
                                height: 45px;
                            }
                            .react-calendar__tile--now:enabled:hover,
                            .react-calendar__tile--now:enabled:focus {
                                width: 45px;
                                height: 45px;
                                background: #413c69 !important;
                                color: #fff !important;
                            }
                            .react-calendar__tile--active {
                                width: 45px;
                                height: 45px;
                                background: #413c69 !important;
                                border-radius: 50%;
                                abbr {
                                    color: #fff;
                                }
                            }
                            .react-calendar__tile--active:enabled:hover,
                            .react-calendar__tile--active:enabled:focus {
                                background: none;
                            }
                        }
                    }
                }
            }
            h6 {
                //styleName: M3/title/small;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.10000000149011612px;
                text-align: left;
                color: #49454f;
            }
            .time-slots {
                display: flex;
                flex-wrap: wrap;
                .active {
                    background-color: #333269;
                    color: #fff !important;
                }
                .slots {
                    cursor: pointer;
                    border: 1px solid #0000001a;
                    box-shadow: #0000001a;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.10000000149011612px;
                    text-align: left;
                    color: #272727;
                    height: 34px;
                    // width: 70px;
                    padding: 5px 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 5px;
                    border-radius: 4px;
                    margin-top: 10px;
                    &:nth-child(4n) {
                        margin-right: 0px;
                    }
                }
            }
        }
        .modal-footer {
            border-top: 1px solid #cac4d0;
            button {
                height: 45px;
                padding: 0px 32px;
                background-color: transparent;
                border: none;
                color: #6750a4;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                &:nth-child(2) {
                    background-color: $button-color;
                    color: #fff;
                    border-radius: 4px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .counselling-holder {
        width: 94%;
        margin-left: 10px;
        margin-bottom: 50px;
        // margin-right: 15px;
        .counselling-introduction {
            padding: 10px;
            flex-wrap: nowrap;
            height: unset;
            min-height: 175px;
            .introduction {
                h3 {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                }
                .counselling-btn {
                    button {
                        width: 160px;
                    }
                }
            }
            .career-codes {
                width: 100%;
                .career-code {
                    margin-left: 0px;
                    padding: 10px;
                    .progress-circular {
                        width: 100%;
                    }
                }
            }
            .counselling-img {
                top: 27px;
                img {
                    width: 165px;
                }
            }
        }
        .how-to-works {
            .col-md-4 {
                width: 87%;
            }
        }
        .why-counselling {
            .introduction {
                width: 100%;
            }
        }
        .bookings {
            .booking-tabs li {
                margin-right: 20px;
            }
        }
        .self-assessment {
            margin-top: 20px;
            padding: 18px;
            background-color: #fff;
            box-shadow: 0px 3px 25px 0px #3c4dac1a;
            background-image: url("../../assets/assesment.svg");
            background-repeat: no-repeat;
            background-position: right;
            background-size: 200px 200px;
            h4 {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #000000;
            }
            button {
                margin-top: 15px;
                border: none;
                background-color: $button-color;
                color: #fff;
                border-radius: 4px;
                padding: 0px 20px;
                height: 38px;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }
    .schedule-modal {
        width: unset;
    }
    .edit-schedule-modal {
        width: unset;
    }

    .test-report-holder {
        h2 {
            flex-wrap: wrap;
            button {
                margin-top: 10px;
            }
        }
        .career-interests {
            padding: 20px 20px;
            .career-interest-progress {
                width: 100%;
                margin-top: 20px;
                .progress-circular {
                    width: 100%;
                }
            }
        }
        .suggested-career {
            .div1 {
                .suggest-job-roles {
                    flex-wrap: wrap;
                    .job-roles {
                        width: 100%;
                    }
                }
            }
        }
    }

    .self-assessment-test-view {
        overflow: auto;
        .instruction-holder {
            flex-wrap: wrap;
            flex-direction: column-reverse;
            button {
                width: 100%;
            }
        }
        .question-div {
            .question-options {
                .question-option {
                    margin-right: 12px;
                    img {
                        width: 55px;
                        height: 55px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .counselling-holder {
        width: 80%;
        .why-counselling .counselling-img img {
            width: 230px !important;
        }
    }
    .self-assessment-test-view {
        .container {
            width: 100% !important;
        }
        .instruction-holder {
            padding: 0px;
        }
    }
    .question-modal {
        .modal-content {
            width: 1000px;
        }
    }
}
