@import "../../App.scss";

.login-banner {
    .login-banner-image {
        margin-top: 20px;
        img {
            width: 75%;
            // height: 60vh;
        }
    }
}
.policy-link {
    position: absolute;
    bottom: 20px;
    z-index: 9999;
    transform: translate(0% -50%);
    p {
        color: $button-color;
        font-size: 12px;
        font-weight: bold;
    }
}

.login-holder {
    background: #fff;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
    padding: 35px 35px 30px;
    width: 370px;
    border-radius: 4px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    input {
        border: 1px solid $chart-color;
        height: 48px;
        &:focus {
            border-color: $chart-color;
            box-shadow: none;
        }
    }
    .has-search {
        position: relative;
        .form-control-feedback {
            position: absolute;
            z-index: 5;
            display: block;
            width: 2.375rem;
            height: 2.375rem;
            line-height: 2.375rem;
            text-align: center;
            pointer-events: none;
            color: #aaa;
            margin-top: 9px;
            width: 24px;
            margin-left: 9px;
        }

        .form-control {
            padding-left: 2.3rem;
        }
    }
    .error {
        color: red;
        text-align: left;
        margin-top: 5px;
        font-size: 14px;
    }
    span {
        color: red;
        font-size: 14px;
    }
    .login-button {
        background-color: $button-color;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        border: none;
        width: 100%;
        height: 48px;
    }
    .elite-lang-select {
        display: flex;
        justify-self: flex-start;
        align-items: center;
        margin-top: 10px;
        div {
            width: 50%;
            height: 40px;
            background: #fff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #535259;
            cursor: pointer;
            &:nth-child(2) {
                margin-left: 10px;
            }
            &:nth-child(3) {
                margin-left: 10px;
            }
        }
    }
    .forgot-btn {
        color: $button-color;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
        cursor: pointer;
    }
    p {
        margin-top: 10px;
    }
}
.otp-holder {
    text-align: center;
    .radio-btns {
        margin-top: 20px;
        margin-bottom: 15px;
        text-align: left !important;
        input {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        .form-check-input {
            margin-top: 0px;
        }
    }
    h3 {
        font-size: 30px;
    }
    .primary-button {
        background-color: $button-color;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        border: none;
        width: 100%;
        height: 48px;
    }
    .otp-fields {
        p {
            margin-bottom: 20px;
        }
        input {
            height: 48px;
        }
    }
}

@media (max-width: 767px) {
    .login-banner {
        display: none;
    }
    .otp-holder {
        .otp-input {
            width: 40px !important;
        }
    }
}

.primary-button {
    background-color: $button-color;
    position: relative;
}

.otp-input {
    width: 36.5px !important;
    height: 45px;
    margin: 6px !important;
    color: #000;
    font-size: 13px;
    border-radius: 4px;
}
.reset-password {
    .form-group {
        margin-top: 35px;
    }
}
.otp-fields {
    .form-group {
        input {
            margin-bottom: 10px;
        }
    }
}

// Media Query

@media (max-width: 767px) {
    .banner-header-content {
        padding-right: 15px !important;
        padding-bottom: 15px;
    }

    .login-main-holder {
        padding: 1rem !important;

        .banner-header-login {
            .logo-main-holder {
                margin: 0;
            }
            h1 {
                display: none;
            }
            p {
                display: none;
            }
        }

        .login-holder {
            width: 100% !important;
            .form-group {
                span {
                    top: -8px !important;
                    line-height: 100%;
                    text-align: center;
                    width: 100%;
                }
                input {
                    font-size: 14px;
                }
            }
        }
        .banner-header-content {
            .banner-creative {
                display: none;
            }
        }
    }
}

@media (max-width: 375px) {
    .login-holder {
        margin: 0;
        padding: 30px !important;
    }
}

@media (min-width: 768px) {
    .login-container {
        padding-top: 43px;
    }
}

@media (max-width: 480px) {
    .login-holder {
        .reset-password {
            .otp-fields {
                .otp-input {
                    width: 36px !important;
                    height: 36px;
                    margin: 2px !important;
                }
            }
        }
    }
}
