:root {
    --button-color: #f5821f;
    --sidebar-color: #fff;
    --dark-background: #1d8bcb;
    --darker-background: #166b9c;
    --chart-color: #5eb5e7;
    --success: #00b24b;
    --heading: #484750;
}

$button-color: var(--button-color);
$sidebar-color: var(--sidebar-color);
$dark-background: var(--dark-background);
$darker-background: var(--darker-background);
$chart-color: var(--chart-color);
$success: var(--success);
$heading: var(--heading);

.chat-bot-controller {
    bottom: 62px !important;
}

.layout {
    position: fixed;
    opacity: 0.8;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: #f5f5f5;
    display: none;
    cursor: not-allowed;
    overflow: hidden;
}

#fc_frame,
#fc_frame.fc-widget-normal {
    z-index: 999999999 !important;
}
.custom_fc_frame {
    bottom: 15px;
    height: 75px;
    min-height: 73px;
    min-width: 65px;
    overflow: hidden;
    position: fixed;
    right: 15px;
    z-index: 2147483600;
}

ul {
    padding-left: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0px;
}

.border-none {
    border: none !important;
}

.form-group {
    margin-bottom: 0.5rem;
}

.cursor-pointer {
    cursor: pointer;
}

//main heading

h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $heading;
}

//section heading
h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: $heading;
}

.caption-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: $heading;
}

.elipsis-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text-button {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: $button-color;
}
.text-right {
    text-align: right !important;
}
.float-right {
    float: right !important;
}
.daterange {
    position: absolute;
    z-index: 999;

    margin-top: 10px;
    .DateRangePicker {
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 5px 7px 2px #0000001f;
    }
}

.MuiSnackbar-anchorOriginBottomCenter {
    width: 30%;
    .MuiPaper-root {
        // background-color: $dark-background !important;
        font-weight: 600 !important;
        font-family: Roboto, sans-serif !important;
        justify-content: center;
        border-radius: 0px !important;
    }
    .MuiSnackbarContent-message {
        font-size: 15px;
        color: #fff;
    }
    .mdc-snackbar {
        z-index: 9999 !important;
        text-align: center;
    }
}

.popover-history {
    padding: 5px 10px;
    font-family: Roboto;
    max-height: 200px;
    overflow: auto;
    & ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: white;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        max-height: 10px;
        background-color: #e9e8e8;
    }
    .close-details {
        position: absolute;
        right: 10px;
        top: 12px;
        cursor: pointer;
        img {
            width: 14px;
        }
    }
    h4 {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        color: #484750;
    }
    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0px;
        color: #696873;
    }
    .history {
        list-style: none;
        padding: 5px;
        li {
            font-family: Roboto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #dddde2;
            padding: 5px;
            p {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                text-align: left;
            }
        }
    }
}

.empty-list {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    margin-top: 8px;
    img {
        width: 350px;
        height: 210px;
    }
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        margin-top: 15px;
        color: #696873;
    }
}

.elite-content-holder {
    width: 45%;
    .modal-header {
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        color: white;
        font-size: 20px;
        font-family: Roboto;
    }
    .main-modal-header {
        padding: 10px 12px;
        background: #332e59;
        h3 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #fff;
            width: 95%;
        }
        p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #fff;
        }
    }
    .like-btn {
        display: flex;
        list-style: none;
        // margin-top: 30px;
        margin-left: -5px;
        margin-top: 10px;
        .liked-active {
            color: #8b7df2 !important;
        }
        ul {
            list-style: none;
        }
        li {
            float: none;
            // border: 1px solid #eee;
            padding: 5px 7px;
            // border-radius: 33px;
            // margin-left: 5px;
            // margin-right: 8px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            cursor: pointer;
            color: #9d99bf;
            img {
                vertical-align: middle;
            }
        }
    }
    button {
        background-color: $button-color;
        border: none;
        color: #fff;
        border-radius: 4px;
        height: 38px;
        font-family: Roboto;
        margin-top: 15px;
        width: 150px;
    }
    .sticky-close {
        position: absolute;
        right: 15px;
        top: 18px;
        cursor: pointer;
        img {
            width: 18px;
        }
    }
}
.content-modal-width {
    width: 35%;
}

@media (max-width: 767px) {
    .MuiSnackbar-anchorOriginBottomCenter {
        width: 100%;
    }
}

//View resource
.resource-details {
    margin-right: 0;
    margin-top: 0 !important;
    margin-bottom: 0;
    width: calc(100vw - 291px);
    min-height: calc(100% - 0rem);
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 8;
    background: #fff;
    &::before {
        background: rgba(51, 51, 51, 0.68);
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        content: "";
        width: calc(100vw - 0px);
    }

    .modal-content {
        height: 100%;
        border: 0;
    }

    .modal-body {
        padding: 0;
        background: #f6f6f6;
        height: 100%;

        .notes-details {
            width: 100%;
            background: #fff;
            height: 100%;
            padding: 25px 50px;
            overflow: auto;
            scrollbar-width: thin;
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #e9e8e8;
            }
            .playlist-header {
                position: relative;
                h1 {
                    small {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 22px;
                        font-family: Roboto;
                    }
                }
                p {
                    color: gray;
                    font-size: 13px;
                }
                .sticky-cross-holder {
                    position: absolute;
                    right: -35px;
                    display: inline-block;
                    font-size: 1rem;
                    line-height: 1.5;
                    vertical-align: middle;
                    top: 2px;
                    cursor: pointer;
                    img {
                        width: 18px;
                    }
                }
            }
            .view-btn {
                background-color: $button-color !important;
                color: #fff;
                border: none;
                width: 220px;
                height: 40px;
                border-radius: 4px;
            }
            .quiz-holder {
                margin-top: 100px;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                align-items: center;
                h1 {
                    font-size: 20px;
                }
                .ques-title {
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #555555;
                    p {
                        min-height: 30px;
                        span {
                            line-height: 24px;
                        }
                    }
                }

                ul {
                    list-style: none;
                    margin-top: 10px;
                    width: 80%;
                    li {
                        // display: flex;
                        justify-content: center;
                        align-items: center;
                        .option {
                            border: 1px solid #ddd;
                            width: 70%;
                            min-height: 35px;
                            margin-top: 10px;
                            box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                            padding: 5px;
                            margin-left: 17px;
                            cursor: pointer;
                            margin-right: -10px;
                            color: #555555;
                            p {
                                color: #555555;
                                span {
                                    color: #555555;
                                }
                                img {
                                    vertical-align: middle;
                                }
                            }
                        }

                        .option1 {
                            border: 1px solid #ddd;
                            width: 70%;
                            min-height: 35px;
                            margin-top: 10px;
                            box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                            padding: 5px;
                            margin-left: 29px;
                            cursor: pointer;
                            color: #555555;
                            p {
                                color: #555555;
                                span {
                                    color: #555555;
                                }
                                img {
                                    vertical-align: middle;
                                    &:hover {
                                        -ms-transform: scale(4); /* IE 9 */
                                        -webkit-transform: scale(4); /* Safari 3-8 */
                                        transform: scale(4);
                                        position: relative;
                                        /* right: -380px; */
                                        z-index: 9999;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .description {
                margin-left: -27px;
                background: #fff;
                padding: 15px;
                border-radius: 6px;
                // height: calc(100vh - 275px);
                .row {
                    justify-content: flex-start;
                    align-items: flex-start;
                }
                .articlediv {
                    min-height: 160px;
                    overflow: auto;
                    .content-middle {
                        border-radius: 6px;
                        height: calc(100vh - 50px);
                        width: 100%;
                        overflow-y: auto;
                        position: relative;
                        margin-top: 15px;
                    }
                }
                .embed-responsive-item {
                    width: 80%;
                    height: 320px;
                }
            }
            .like-btn {
                display: flex;
                list-style: none;
                margin-top: 30px;
                .liked-active {
                    color: $chart-color !important;
                }
                li {
                    float: none;
                    border: 1px solid #eee;
                    padding: 5px 7px;
                    border-radius: 33px;
                    margin-left: 5px;
                    margin-right: 8px;
                    font-size: 14px;
                    cursor: pointer;
                    font-weight: 500;
                    img {
                        vertical-align: middle;
                    }
                }
            }

            .save-notes {
                width: 50%;
                textarea {
                    resize: both;
                }
                .addWidth {
                    // margin-top: -45px;
                    margin-left: -2px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .removeWidth {
                    margin-top: 0px;
                    margin-left: 0px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                p {
                    //styleName: Action Text;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $button-color;
                }
                span {
                    //styleName: Body Text;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0px;
                    color: #9b9aa3;
                }
            }
            .videodiv {
                .sticky-cross-holder {
                    position: absolute;
                    right: -20px;
                    display: inline-block;
                    font-size: 1rem;
                    line-height: 1.5;
                    vertical-align: middle;
                    top: 1px;
                    cursor: pointer;
                    img {
                        width: 18px;
                    }
                }
                .content-details {
                    position: relative;
                }

                .content-middle1 {
                    background: #fff;
                    padding: 0px;
                    border-radius: 6px;
                    height: calc(100vh - 100px);
                    // overflow-y: auto;
                    .elipsis {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    .question {
                        padding-left: 23px;
                        text-align: left;
                    }
                    h6 {
                        font-size: 1.1rem;
                        margin-bottom: 1rem;
                        font-weight: 400;
                        color: #d4d4d4;
                    }

                    p {
                        margin-bottom: 20px;
                        line-height: 24px;
                    }
                }
            }
        }
        .react-pdf__Page__textContent {
            display: none;
        }
        .content-middle1 {
            background: #fff;
            padding: 15px;
            border-radius: 6px;
            height: calc(100vh - 50px);
            width: 85%;
            overflow-y: auto;
            position: relative;
            border: 1px solid #cccccc;
            margin-top: 15px;
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #e9e8e8;
            }
            .tools {
                position: absolute;
                top: 10px;
                button {
                    border: none;
                    background-color: transparent;
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                    img {
                        width: 20px;
                    }
                    &:hover {
                        background-color: #f6f6f6;
                        border-radius: 50%;
                    }
                }
            }
            .react-pdf__Document {
                position: relative;
                .zoom-btn {
                    position: absolute;
                    // right: 10px;
                    top: -10px;
                    z-index: 1;
                    button {
                        border: none;
                        background-color: transparent;
                        width: 45px;
                        height: 45px;
                        img {
                            width: 20px;
                        }
                        &:hover {
                            background-color: #f6f6f6;
                            border-radius: 50%;
                        }
                    }
                }
                .react-pdf__Page {
                    scale: unset;
                    span {
                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -o-user-select: none;
                        user-select: none;
                    }
                }
                .react-pdf__Page__annotations {
                    display: none !important;
                }
            }
        }
        .nextPage {
            border: none;
            background-color: #6457e8;
            width: 25px;
            height: 25px;
            padding-bottom: 3px;
            border-radius: 50%;
            margin-top: 8px;
        }
    }
}

@media (max-width: 767px) {
    .resource-details {
        top: 67px;
        max-width: 100%;
        width: 100%;
        z-index: 5;
        margin-top: 0;
        padding-bottom: 0;
        .modal-body {
            .padd {
                padding: 15px 30px !important;
            }

            .notes-details {
                padding: 15px;
                overflow-x: hidden;
                .playlist-header {
                    .topic-back {
                        width: 80%;
                    }
                    .sticky-cross-holder {
                        width: 35px;
                        top: -5px !important;
                        right: -12px !important;
                        height: 35px;
                        background: #fff;
                        border: 1px solid #f5821f;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 6px;
                        img {
                            width: 14px;
                        }
                    }
                }
                .description {
                    padding-right: 0px;
                    padding-left: 12px;
                    margin-right: -15px;

                    .embed-responsive-item {
                        width: 100%;
                        height: 315px;
                    }
                    .activeFeed-image {
                        margin-top: 20px;
                        display: flex;
                        justify-self: center;
                        padding-right: 0px;
                        img {
                            width: 96% !important;
                        }
                    }
                }
                .save-notes {
                    width: 100%;
                    margin-bottom: 125px;
                }
                .videodiv {
                    .sticky-cross-holder {
                        width: 35px;
                        height: 35px;
                        background: #fff;
                        border: 1px solid #f5821f;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 6px;
                        img {
                            width: 14px;
                        }
                        z-index: 2;
                        top: -9px;
                        right: -10px;
                    }
                }
            }
            .react-pdf__Document {
                overflow: auto;
                .react-pdf__Page {
                    .react-pdf__Page__canvas {
                        display: block;
                        user-select: none;
                        // width: 100% !important;
                        height: 840px;
                        // margin-left: -50px;
                    }
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .videodiv .content-middle1 {
        width: 55%;
        margin: 0 auto;
    }
}
.cursor-pointer {
    cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .resource-details {
        max-width: 100%;
        width: 100%;

        .header-div {
            padding: 10px 15px !important;

            .left {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .right-icon {
                button {
                    padding: 0.375rem 0.5rem;
                }
            }
        }
    }
}
.text-left {
    text-align: left !important;
}
.privacy-policy-holder {
    background-color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    h3 {
        font-family: "Roboto";
        font-size: 34px;
        font-weight: 500;
        text-align: center;
    }
    .policy-desc {
        h4 {
            font-family: "Roboto";
            font-size: 32px;
            font-weight: 400;
            line-height: 40px;
            text-align: left;
            color: $heading;
        }
        ol {
            margin-top: 20px;
        }
        h5 {
            font-family: "Roboto";
            font-size: 20px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #696873;
        }
        p {
            margin-top: 10px;
            font-family: "Roboto";
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            text-align: left;
            color: #696873;
            ul {
                padding-left: 2rem;
            }
        }
    }
}
