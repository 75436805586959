@import "../../App.scss";

.modal {
  backdrop-filter: saturate(150%) blur(5px) !important;
}

.share-modal {
  width: 29%;
  .modal-header {
    button {
      background-color: transparent;
      border: none;
      font-size: 30px;
    }
  }
  .share-holder {
    .col-sm-12.email-id-row {
      border: 1px solid #ccc;
      height: auto;
      max-height: 60px;
      margin-bottom: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      cursor: pointer;
    }
    .col-sm-12.email-id-row input {
      border: 0px;
      outline: 0px;
      width: 50%;
    }
    span.to-input {
      display: block;
      float: left;
      padding-right: 7px;
    }
    .all-mail {
      display: flex;
      flex-wrap: wrap;
      &::-webkit-input-placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font: normal normal normal 18px/25px Roboto;
        // font-size: 18px;
        line-height: normal;
        color: #d3d3d3;
        letter-spacing: 0px;
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font: normal normal normal 18px/25px Roboto;
        line-height: normal;
        color: #d3d3d3;
        letter-spacing: 0px;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        font: normal normal normal 18px/25px Roboto;
        line-height: normal;
        color: #d3d3d3;
        letter-spacing: 0px;
      }
      .MuiChip-sizeSmall {
        background: #f0f0f0 0% 0% no-repeat padding-box;
      }
    }

    .email-suggestion {
      position: fixed;
      z-index: 6;
      width: 25%;
      // margin-left: 6px;
      max-width: 90%;
      max-height: 200px;
      overflow-x: hidden;
      overflow-y: auto;
      // left:2px;
      background: #fff;
      box-shadow: 0px 5px 7px 2px #0000001f;
      text-align: left;
      margin-top: -10px;
      // border-bottom-left-radius: 5px;
      border-radius: 4px;
      & ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: white;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background-color: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        max-height: 10px;
        background-color: #e9e8e8;
      }
      i {
        margin-right: 7px;
      }
      hr {
        margin-top: -10px;
      }
      ul {
        &:hover li:hover {
          background: #ddd;
        }
        li {
          display: block;
          cursor: pointer;
          width: 100%;
          padding: 7px 0 6px 20px;
          border-bottom: 1px solid #00000026;
          margin: 0;
          font-size: 16px;

          // padding-left: 11px;
          .intial {
            border-radius: 50px;
            font-size: 17px;
            width: 36px;
            height: 36px;
            // border: 1px solid #6457e8;
            display: flex;
            justify-content: center;
            align-items: center;
            // max-width: 37px;
            color: #fff;
          }
          .email-tag {
            margin-left: 45px;
            margin-top: -42px;
          }
        }
      }
    }
  }
  .specific {
    // border:1px solid black;
    width: 100%;
    height: 110px;
    max-height: 350px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 15px;
    margin-top: 15px;
    .share-title {
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
      .elipsis {
        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          img {
            display: none;
          }
        }
        span {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      div {
        // width: 75%;
        // font: normal normal Roboto;
        color: #000000;
        p {
          display: flex;
          justify-content: flex-start;

          // margin-top: 10px;

          span {
            margin-top: 10px;
            margin-left: 5px;
            padding-right: 10px;
            font: normal normal normal 15px/12px Roboto;
            letter-spacing: 0px;
            color: #767676;
          }
          .circle-feed {
            width: 6px;
            height: 6px;
            background: #767676;
            border-radius: 50%;
            vertical-align: -webkit-baseline-middle;
            margin-top: 14px;
          }
          .skills {
            max-width: 200px;
            margin-top: 11px;
            padding-left: 10px;
            .skill-list {
              font: normal normal normal 14px/13px Roboto;
              letter-spacing: 0px;
              color: #767676;
            }
          }
          img {
            width: 16px;
            height: 16px;
            margin-top: 8px;
          }
        }
      }
      .content-pic {
        margin-left: 10px;
      }
    }
    .hr {
      border-top: 1px solid #ebebeb;
      margin-top: 25px;
      width: 100%;
      margin-bottom: 9px;
    }
    .this {
      font: normal normal normal Roboto;
      letter-spacing: 0px;
      color: #767676;
      font-size: 13px;
    }
    .share-icon {
      display: flex;
      .icon {
        // font-size: 15px;
        padding-right: 24px;
        margin-top: 10px;
        cursor: pointer;
        img {
          width: 28px;
          margin-left: 4px;
        }
        p {
          font-size: 8px;
          text-align: left;
          margin-left: 2px;
          font: Regular Roboto;
          // letter-spacing: 0px;
          color: #767676;
          opacity: 1;
        }
      }
    }
  }

  .share-btn {
    border: none;
    color: white;
    padding: 10px 20px;
    margin-right: -15px;
    border-radius: 4px;
    background: $button-color;
  }
}

@media (max-width: 767px) {
  .share-modal {
    width: 96%;
  }
  // .share-modal {
  //   height: 541px;
  //   max-height: 550px;
  // }
  .share-modal {
    .modal-body {
      padding-bottom: 60px;
    }
    .share-holder {
      span.email-ids {
        float: left;
        /* padding: 4px; */
        border: 1px solid #ccc;
        margin-right: 5px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 5px;
        background: #f5f5f5;
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 5px;
      }
      span.cancel-email {
        border: 1px solid #ccc;
        width: 18px;
        display: block;
        float: right;
        text-align: center;
        margin-left: 20px;
        border-radius: 49%;
        height: 18px;
        line-height: 15px;
        margin-top: 1px;
        cursor: pointer;
      }
      .col-sm-12.email-id-row {
        border: 1px solid #ccc;
        height: auto;
        max-height: 60px;
        margin-bottom: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        cursor: pointer;
      }
      .col-sm-12.email-id-row input {
        border: 0px;
        outline: 0px;
        width: 100%;
      }
      .all-mail {
        &::-webkit-input-placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          font: normal normal normal 16px/25px Roboto;
          // font-size: 18px;
          line-height: normal;
          color: #d3d3d3;
          letter-spacing: 0px;
          opacity: 1; /* Firefox */
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          font: normal normal normal 16px/25px Roboto;
          font-size: 16px;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          font: normal normal normal 16px/25px Roboto;
          font-size: 16px;
        }
      }
      span.to-input {
        display: block;
        float: left;
        padding-right: 4px;
      }

      .specific {
        // border:1px solid black;
        width: 100%;
        // height: 245px;
        background: none;
        box-shadow: none;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 4px;
        // border-radius: 4px;
        // opacity: 1;

        .share-title {
          display: flex;
          justify-content: space-between;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 6px #00000029;
          padding-left: 25px;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-right: 10px;
          width: 100%;
          /* padding: 12px; */
          margin-top: 15px;
          border-radius: 4px;
          opacity: 1;
          div {
            margin-left: -11px;
            p {
              display: flex;
              justify-content: flex-start;
              // font-size: 10px;
              // margin-top: 10px;
              // font: normal normal normal 12px/15px Roboto;
              // letter-spacing: 0px;
              // color: #767676;
              .circle-feed {
                width: 10px;
                height: 7px;
                background: #767676;
                border-radius: 50%;
                vertical-align: -webkit-baseline-middle;
                margin-top: 14px;
                // margin-left: 8px;
              }
              span {
                margin-top: 10px;
                margin-left: 5px;
                padding-right: 18px;
                font: normal normal normal 15px/12px Roboto;
                letter-spacing: 0px;
                color: #767676;
              }
              .skills {
                max-width: 200px;
                margin-top: 0px;
                .skill-list {
                  font: normal normal normal 14px/13px Roboto;
                  letter-spacing: 0;
                  color: #767676;
                  margin-left: 6px;
                  padding-top: 10px;
                }
              }
              img {
                width: 20px;
                height: 20px;
                margin-top: 8px;
              }
            }
          }
          .content-pic {
            padding-left: 0px;
          }
        }
        .hr {
          display: none;
        }
        .icon-header {
          padding-top: 25px;
          .this {
            margin-left: -5px;
          }
          .share-icon {
            display: flex;
            justify-content: space-between;
            margin-left: -15px;
            .icon {
              padding-right: 17px;
              p {
                font-size: 9px;
                padding-left: 6px;
              }
            }
            img {
              width: 32px;
              margin-left: 8px;
            }
          }
        }
      }

      .email-suggestion {
        position: fixed;
        z-index: 6;
        // max-width: 62%;
        width: 81%;
        // left:2px;
        background: #fff;
        box-shadow: 0px 5px 7px 2px #0000001f;
        text-align: left;
        margin-top: -10px;
        // border-bottom-left-radius: 5px;
        border-radius: 4px;
        // padding-bottom: 14px;
        // padding-top: 12px;

        i {
          margin-right: 7px;
        }
        hr {
          margin-top: -10px;
        }
        ul {
          &:hover li:hover {
            background: #ddd;
          }
          li {
            display: block;
            cursor: pointer;
            width: 100%;
            padding: 7px 0 6px 20px;
            border-bottom: 1px solid #00000026;
            margin: 0;
            font-size: 16px;
            // padding-left: 11px;
            .intial {
              border-radius: 50px;
              font-size: 15px;
              width: 31px;
              height: 31px;
              // border: 1px solid #6457e8;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .email-tag {
              margin-left: 40px;
              margin-top: -35px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .modal-content {
    width: 400px;
  }
  .share-holder .email-suggestion {
    width: 35%;
  }
}
